import * as React from "react";
import Box from "@mui/material/Box";
import { _NotesAndDocuments } from "../lists/NotesAndDocuments";

export default function ResponsiveStudentNotesAndDocuments() {
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      className="small-screen"
      sx={{
        height: 405,
        boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.1)",
        border: "1px solid #e5e5e5",
        background: "white",
        position: "fixed",
        width: 525,
        bottom: open ? 0 : -333,
        right: 20,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
      }}
    >
      <Box sx={{ padding: "25px 30px" }}>
        <_NotesAndDocuments
          handleDrawerToggle={() => setOpen(!open)}
          open={open}
          style={{ maxHeight: 280, overflow: "auto" }}
          showToggleButton
          source="student"
        />
      </Box>
    </Box>
  );
}
