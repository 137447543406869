import { Box } from "@mui/material";
import { autovioColors } from "./backofficeTheme";

function IconInACircle({ icon }: { icon: JSX.Element }) {
  const { greyUltraLight } = autovioColors;
  return (
    <Box
      sx={{
        background: greyUltraLight,
        color: "#9b9b9b",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 40,
        width: 40,
        borderRadius: "50%",
      }}
    >
      {icon}
    </Box>
  );
}

export default IconInACircle;
