import { collection, getDocsFromServer, onSnapshot, Unsubscribe } from "firebase/firestore";
import {
  CreateParams,
  CreateResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { AbstractProvider } from "./abstractProvider";
import { Note, NoteSchema } from "../model/Note";
import { firestore } from "../firebase";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { createNote, editNote } from "../api/backoffice.api";
import { t } from "../model/types";

abstract class AbstractNotesProvider extends AbstractProvider<Note> {
  private _snapshotPromiserecordId: Identifier | undefined;
  private _snapshotPromise: Promise<Array<Note>> | undefined;
  private _unsubscribe: Unsubscribe | undefined;
  private readonly _onUpdateListeners: Array<() => void> = [];

  constructor(private readonly resource: "studentNotes" | "drivingSchoolNotes") {
    super();
  }

  async getOne(_: string, { id }: GetOneParams<Note>): Promise<GetOneResult<Note>> {
    const notes = await this._snapshotPromise!;
    const note = notes.find((it) => it.id === id);
    if (!note) {
      throw new Error(`Could not find note ${JSON.stringify(id)}`);
    }
    return { data: note };
  }

  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Note>> {
    if (this.resource === "studentNotes" && target !== "studentUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentUid"`);
    }
    if (this.resource === "drivingSchoolNotes" && target !== "drivingSchoolUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "drivingSchoolUid"`);
    }
    const notes = await this.fetchIfNeeded(id);
    return applyPagination(applySort(applyFilter(notes, filter), sort), pagination);
  }

  async update(_: string, { data }: UpdateParams<Note>): Promise<UpdateResult<Note>> {
    const { id, studentUid, body } = t
      .object({
        id: t.uid(),
        studentUid: t.uid(),
        body: t.string(),
      })
      .parse(data);
    await editNote({ studentUid, noteUid: id, body });
    const notes = await this.fetch(studentUid);
    const note = notes.find((it) => it.id === id)!;
    return { data: note };
  }

  async create(_: string, { data }: CreateParams<{ recordId: string; body: string }>): Promise<CreateResult<Note>> {
    const { recordId, body } = data;
    if (typeof recordId !== "string") throw new Error("Property recordId must be provided.");
    if (!recordId) throw new Error("Property recordId must not be empty.");
    if (typeof body !== "string") throw new Error("Property body must be provided.");
    const knownIds = new Set((await this.fetchIfNeeded(recordId)).map((note) => note.id));
    // TODO: When we create driving school notes we need a little different call. That's to come later.
    await createNote({ studentUid: recordId, body });
    const notes = await this.fetch(recordId);
    const newNote = notes.find((it) => !knownIds.has(it.id))!;
    return { data: newNote };
  }

  fetchIfNeeded(recordId: Identifier): Promise<Array<Note>> {
    if (this._snapshotPromiserecordId === recordId) {
      return this._snapshotPromise!;
    }
    return this.fetch(recordId);
  }

  fetch(recordId: Identifier): Promise<Array<Note>> {
    const path = this.resource === "studentNotes" ? `/users/${recordId}/notes` : `/drivingSchools/${recordId}/notes`;
    const firestoreCollection = collection(firestore, path);
    this._unsubscribe?.();
    this._unsubscribe = undefined;
    this._snapshotPromiserecordId = recordId;
    this._snapshotPromise = getDocsFromServer(firestoreCollection).then(async (snapshot) => {
      let notes = snapshot.docs.map((doc) => NoteSchema.parse({ studentUid: recordId, ...doc.data() }));
      console.info(`Retrieved ${notes.length} note(s) for student ${recordId}`);
      this._unsubscribe = onSnapshot(firestoreCollection, async (snapshot) => {
        const updatedNotes = await Promise.all(
          // TODO: We might need to touch the NoteSchema as well.
          snapshot.docs.map((doc) => NoteSchema.parse({ studentUid: recordId, ...doc.data() })),
        );
        // Ignore incomplete snapshots by checking their length ...
        if (updatedNotes.length < notes.length) {
          return;
        }
        notes = updatedNotes;
        this._snapshotPromise = Promise.resolve(notes);
        console.info(`Retrieved new snapshot with ${notes.length} note(s) for student ${recordId}`);
        for (const listener of this._onUpdateListeners) {
          try {
            listener();
          } catch (error) {
            console.error("listener failed", error);
          }
        }
      });
      return notes;
    });
    return this._snapshotPromise!;
  }

  onUpdate(listener: () => any): () => void {
    if (this._onUpdateListeners.indexOf(listener) >= 0) {
      throw new Error("listener already registered");
    }
    this._onUpdateListeners.push(listener);
    return () => {
      const i = this._onUpdateListeners.indexOf(listener);
      if (i < 0) {
        throw new Error("listener already unregistered");
      }
      this._onUpdateListeners.splice(i, 1);
    };
  }
}

class StudentNotesProvider extends AbstractNotesProvider {}
class DrivingSchoolNotesProvider extends AbstractNotesProvider {}

export const studentNotesProvider = new StudentNotesProvider("studentNotes");
export const drivingSchoolNotesProvider = new DrivingSchoolNotesProvider("drivingSchoolNotes");
export type NotesSource = "studentNotes" | "drivingSchoolNotes";
