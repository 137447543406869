import { Box, Stack, Typography } from "@mui/material";
import {
  Labeled,
  Link,
  NumberField,
  NumberInput,
  TextField,
  TextInput,
  useCreatePath,
  useGetRecordId,
  useRecordContext,
} from "react-admin";
import { grants } from "../../backoffice.access_control";
import { AgeField } from "../../fields/AgeField";
import { DateField } from "../../fields/DateField";
import { PostalAddressField } from "../../fields/address/PostalAddressField";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { CommaSeparatedFieldList } from "../../misc/CommaSeparatedFieldList";
import { Student } from "../../providers/studentsProvider";
import { MoneyField } from "../../fields/MoneyField";
import { PhoneNumberField } from "../../fields/PhoneNumberField";
import { AuthProvidersField } from "../../fields/AuthProvidersField";
import { LabeledAndEditable } from "../../misc/LabeledAndEditable";
import { YesNoInput } from "../../inputs/YesNoInput";
import { YesNoField } from "../../fields/YesNoField";
import { FundingInstructionsField } from "../../fields/FundingInstructionsField";
import { EditPostalAddressButton } from "../../buttons/address/EditPostalAddressButton";
import { InvoiceAddressField } from "../../fields/address/InvoiceAddressField";
import { EditInvoiceAddressButton } from "../../buttons/address/EditInvoiceAddressButton";
import { ResetPasswordLink } from "../../links/ResetPasswordLink";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { MoneyInput } from "../../inputs/MoneyInput";
import { DrivingSchoolChangeField } from "../../fields/DrivingSchoolChangeField";
import { DrivingSchoolChangeInput } from "../../inputs/DrivingSchoolChangeInput";
import { autovioColors, overviewCardStyle } from "../../misc/backofficeTheme";

export function StudentProfileTab() {
  const student = useRecordContext<Student>();

  if (!student) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={{ ...overviewCardStyle, padding: "25px 30px" }}>
      <Stack direction="row" spacing={8}>
        {/* --- First column --- */}
        <Stack spacing={2}>
          <Labeled label="Nutzer erstellt am">
            <DateField source="startDate" emptyText="Keine Angabe" />
          </Labeled>
          {grants.includes("viewAuthProviders") && (
            <Labeled
              label={
                grants.includes("resetPassword") ? (
                  <>
                    Login (<ResetPasswordLink label="Passwort setzen" />)
                  </>
                ) : (
                  "Login"
                )
              }
            >
              <AuthProvidersField />
            </Labeled>
          )}
          <LabeledAndEditable
            label="Wechsel"
            renderInput={({ submit }) => (
              <Labeled label="Wechsel" color={autovioColors.green}>
                <DrivingSchoolChangeInput submit={submit} />
              </Labeled>
            )}
            disableEdit={!student.isSchoolChanger}
          >
            <DrivingSchoolChangeField />
          </LabeledAndEditable>
          <LabeledAndEditable
            label="Vorname"
            disableEdit={!grants.includes("editStudentPersonalData")}
            renderInput={() => <TextInput source="firstName" />}
          >
            <TextField source="firstName" />
          </LabeledAndEditable>
          <LabeledAndEditable
            label="Nachname"
            disableEdit={!grants.includes("editStudentPersonalData")}
            renderInput={() => <TextInput source="lastName" />}
          >
            <TextField source="lastName" />
          </LabeledAndEditable>
          <Labeled label="Geburtsdatum">
            <Stack direction="row" spacing={0.5}>
              <DateField source="dateOfBirth" />
              <AgeField source="dateOfBirth" />
            </Stack>
          </Labeled>
          <Labeled label="Wohnort">
            <Stack direction="row" spacing={1} alignItems="center">
              <PostalAddressField source="postalAddress" emptyText="Keine Angabe" />
              {grants.includes("editStudentPersonalData") && <EditPostalAddressButton />}
            </Stack>
          </Labeled>
          <Stack direction="row" spacing={1} alignItems="center" whiteSpace="nowrap">
            <Labeled label="Rechnungsadresse">
              <InvoiceAddressField source="invoiceAddress" emptyText="Keine Angabe" />
            </Labeled>
            {grants.includes("editStudentPersonalData") && <EditInvoiceAddressButton />}
          </Stack>
          <LabeledAndEditable
            label="Telefonnummer"
            disableEdit={!grants.includes("editStudentPersonalData")}
            renderInput={() => <TextInput source="phoneNumber" />}
          >
            <PhoneNumberField source="phoneNumber" />
          </LabeledAndEditable>
          <LabeledAndEditable label="Benötigt Sehhilfe" renderInput={() => <YesNoInput source="needsGlasses" />}>
            <YesNoField source="needsGlasses" />
          </LabeledAndEditable>
          <Labeled label={<BookedQuotesFieldLabel />}>
            <ReferenceManyField reference="bookedQuotes" target="studentId" sort={{ field: "name", order: "ASC" }}>
              <Box /* Box used here to prevent HTML elements in the list from being rendered in a column */
                sx={{ mt: "-3.5px" }}
              >
                <CommaSeparatedFieldList newLines>
                  <TextField label="Name" source="bundleName" />
                </CommaSeparatedFieldList>
              </Box>
            </ReferenceManyField>
          </Labeled>
          <LabeledAndEditable
            label="Fahrstunden Wochenlimit (UE)"
            renderInput={() => (
              <NumberInput
                source="maxBookableDrivingLessonsPerWeekLU"
                min={0}
                max={20}
                step={1}
                sx={{ width: "210px" }}
              />
            )}
          >
            <NumberField source="maxBookableDrivingLessonsPerWeekLU" emptyText="Kein Limit konfiguriert" />
          </LabeledAndEditable>
        </Stack>

        {/* --- Second column --- */}
        <Stack spacing={2}>
          {grants.includes("viewDunning") && student.paymentStrategy === "purchaseOnAccount" && (
            <LabeledAndEditable
              label="Budget"
              disableEdit={!grants.includes("editStudentBudget")}
              renderInput={() => <MoneyInput label="Budget" source="budget" />}
            >
              <MoneyField source="budget" />
            </LabeledAndEditable>
          )}

          {grants.includes("viewDunning") && (
            <Labeled label="Banküberweisungsdaten">
              <FundingInstructionsField />
            </Labeled>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

function BookedQuotesFieldLabel() {
  const createPath = useCreatePath();
  const recordId = useGetRecordId();
  return (
    <Typography color="textSecondary" variant="caption">
      Gebuchte Pakete (
      <Link
        to={createPath({ resource: "students", id: recordId, type: "packages" })}
        variant="caption"
        underline="none"
      >
        ändern
      </Link>
      )
    </Typography>
  );
}
