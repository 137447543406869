import { auth, firestore } from "../firebase";
import { createApiClient } from "../generated/serverClient";
import { createNote } from "./backoffice.api";
import { assertNever, reportError } from "../backoffice.utils";
import { Student, studentsProvider } from "../providers/studentsProvider";
import { QueryClient } from "react-query";
import { studentNotesProvider } from "../providers/notesProvider";
import { Course } from "../providers/coursesProvider";
import { CancellationPolicy } from "../model/CancellationPolicy";
import { doc, onSnapshot } from "firebase/firestore";
import { isASFCourseSession, isTheoryLesson } from "../model/autovioCalendarEvents";

export { schemas } from "../generated/serverClient";

export async function getAuthenticatedServerClient(): Promise<ReturnType<typeof createApiClient>> {
  const hostname = location.hostname;
  const useLocalBackend = import.meta.env.VITE_USE_LOCAL_BACKEND || false; // needs a browser with CORS disabled to work

  const baseUrl = useLocalBackend
    ? "http://localhost:4242"
    : hostname === "localhost" || hostname.indexOf("dev") > 0
    ? "https://server.autovio.dev"
    : `https://server.autovio.de`;

  const jwt = await auth.currentUser?.getIdToken();
  return createApiClient(baseUrl, { axiosConfig: { headers: { Authorization: `Bearer ${jwt}` } } });
}

class ServerAPI {
  /**
   * Side effects:
   * - If the given noticeType is "student_blocked" the status of the student will be changed to "outstandingPayments".
   * - If the given noticeType is "inkasso_notice" a PAIR Finance case file will be created.
   */
  async sendDunningNotice({
    student,
    dunningProcessId,
    noticeType,
    emailSubject,
    emailText,
    queryClient,
  }: {
    student: Student;
    dunningProcessId: string;
    noticeType: "student_blocked" | "first_dunning_notice" | "second_dunning_notice" | "inkasso_notice";
    emailSubject: string;
    emailText: string;
    queryClient: QueryClient;
  }) {
    let unsubscribe: () => void = () => {
      throw new Error("unsubscribe has not been set");
    };
    const dunningProcessInFirestoreUpdated = new Promise<void>((resolve) => {
      const oldDunningProcess = JSON.stringify(student.dunningProcess);
      unsubscribe = studentsProvider.onUpdate(async () => {
        const { data: newStudent } = await studentsProvider.getOne("students", { id: student.id });
        const newDunningProcess = JSON.stringify(newStudent.dunningProcess);
        if (newDunningProcess !== oldDunningProcess) {
          resolve();
        }
      });
    });
    const serverClient = await getAuthenticatedServerClient();
    let localizedNoticeType: string;
    if (noticeType === "student_blocked") {
      const studentUid = student.id;
      await serverClient.blockStudentAndSendPaymentReminder({ studentUid, dunningProcessId, emailSubject, emailText });
      localizedNoticeType = "Zahlungserinnerung";
    } else if (noticeType === "first_dunning_notice") {
      await serverClient.sendFirstDunningNotice({ dunningProcessId, emailSubject, emailText });
      localizedNoticeType = "1. Mahnung";
    } else if (noticeType === "second_dunning_notice") {
      await serverClient.sendSecondDunningNotice({ dunningProcessId, emailSubject, emailText });
      localizedNoticeType = "2. Mahnung";
    } else if (noticeType === "inkasso_notice") {
      await serverClient.handoverToPairFinance({ dunningProcessId, emailSubject, emailText });
      localizedNoticeType = "Inkasso";
    } else {
      assertNever(noticeType);
    }
    try {
      await createNote({
        studentUid: student.id,
        body: `<p style="font-weight: bold; color: red">Mahnwesen - ${localizedNoticeType}</p>
<p>Wir haben an ${student.firstName} folgende Nachricht geschickt:</>
<p>${emailText.replaceAll("\n", "<br />\n")}</p>`,
      });
    } catch (error) {
      reportError(`Failed to create note "Mahnwesen - ${localizedNoticeType} ..."`, error);
    }
    await dunningProcessInFirestoreUpdated;
    unsubscribe();
    await queryClient.invalidateQueries(["students"]);
    await queryClient.invalidateQueries(["studentNotes"]);
    await queryClient.invalidateQueries(["studentsWithNegativeBalance"]);
    await queryClient.invalidateQueries(["candidatesForBlocking"]);
    await queryClient.invalidateQueries(["candidatesForFirstDunningNotice"]);
    await queryClient.invalidateQueries(["candidatesForFirstDunningNoticeParkingLot"]);
    await queryClient.invalidateQueries(["candidatesForSecondDunningNotice"]);
    await queryClient.invalidateQueries(["candidatesForSecondDunningNoticeParkingLot"]);
    await queryClient.invalidateQueries(["candidatesForHandoverToPairFinance"]);
    await queryClient.invalidateQueries(["candidatesForHandoverToPairFinanceParkingLot"]);
    await queryClient.invalidateQueries(["studentsHandedOverToPairFinance"]);
    await queryClient.invalidateQueries(["candidatesForReactivation"]);
  }

  async sendShoppingVoucher({
    idempotencyKey,
    studentId,
    amount,
    messageToStudent,
    noteForInstructor,
    queryClient,
  }: {
    idempotencyKey: string;
    studentId: string;
    amount: number;
    messageToStudent: string;
    noteForInstructor: string;
    queryClient: QueryClient;
  }) {
    let unsubscribe: () => void = () => {
      throw new Error("unsubscribe has not been set");
    };
    const oldNotes = await studentNotesProvider.fetchIfNeeded(studentId);
    const noteInFirestoreCreated = new Promise<void>((resolve, reject) => {
      unsubscribe = studentNotesProvider.onUpdate(async () => {
        try {
          const newNotes = await studentNotesProvider.fetchIfNeeded(studentId);
          if (newNotes.length > oldNotes.length) {
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    });
    const serverClient = await getAuthenticatedServerClient();
    await serverClient.createGiftVoucher({
      id: idempotencyKey,
      beneficiary: studentId,
      value: { amount, currency: "EUR" },
      description: messageToStudent,
      note: noteForInstructor,
    });
    await noteInFirestoreCreated;
    unsubscribe();
    await queryClient.invalidateQueries(["giftVouchers"]);
    await queryClient.invalidateQueries(["studentNotes"]);
  }

  async addStudentToCourse({
    student,
    course,
    agreedCancellationPolicy,
    queryClient,
  }: {
    student: Student;
    course: Course;
    agreedCancellationPolicy?: CancellationPolicy;
    queryClient: QueryClient;
  }) {
    let unsubscribe: () => void = () => {
      throw new Error("unsubscribe has not been set");
    };
    const lastEvent = course.appointments.at(-1)!;
    const studentWasAdded = new Promise<void>((resolve) => {
      unsubscribe = onSnapshot(doc(firestore, `calendar_events/${lastEvent.id}`), (snapshot) => {
        const studentUids = snapshot.data()?.derived?.studentUids;
        if (Array.isArray(studentUids) && studentUids.includes(student.id)) {
          resolve();
        }
      });
    });
    try {
      const serverClient = await getAuthenticatedServerClient();
      await serverClient.addStudentToCourse({ studentUid: student.id, courseUid: course.id, agreedCancellationPolicy });
      await studentWasAdded;
    } finally {
      unsubscribe();
    }
    void queryClient.invalidateQueries(["calendarEvents"]);
    void queryClient.invalidateQueries(["calendarEventHistory"]);
    void queryClient.invalidateQueries(["courses"]);
    void queryClient.invalidateQueries(["recommendations", "student"]);
  }

  async removeStudentFromCourse({
    student,
    course,
    queryClient,
  }: {
    student: Student;
    course: Course;
    queryClient: QueryClient;
  }) {
    let unsubscribe: () => void = () => {
      throw new Error("unsubscribe has not been set");
    };
    const watchedEvent = course.appointments.findLast((it) =>
      isTheoryLesson(it)
        ? it.students[student.id]?.rsvp === "accepted"
        : isASFCourseSession(it)
        ? it.participants[student.id]
        : false,
    );
    if (!watchedEvent) {
      throw new Error(`Could not find an event in course ${course.id} which is booked by ${student.id}`);
    }
    const studentWasRemoved = new Promise<void>((resolve) => {
      unsubscribe = onSnapshot(doc(firestore, `calendar_events/${watchedEvent.id}`), (snapshot) => {
        const studentUids = snapshot.data()?.derived?.studentUids;
        if (Array.isArray(studentUids) && !studentUids.includes(student.id)) {
          resolve();
        }
      });
    });
    try {
      const serverClient = await getAuthenticatedServerClient();
      await serverClient.removeStudentFromCourse({ studentUid: student.id, courseUid: course.id });
      await studentWasRemoved;
    } finally {
      unsubscribe();
    }
    void queryClient.invalidateQueries(["calendarEvents"]);
    void queryClient.invalidateQueries(["calendarEventHistory"]);
    void queryClient.invalidateQueries(["courses"]);
    void queryClient.invalidateQueries(["recommendations", "student"]);
  }
}

export const serverAPI = new ServerAPI();
