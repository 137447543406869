import { AutovioMap, DrivingSchoolMap } from "./maps";
import { RecordContextProvider, useShowController } from "react-admin";
import { Box, Card, Typography } from "@mui/material";
import { ReactElement } from "react";
import { PageTitle } from "./PageTitle";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { LatestDrivingStudents } from "../details/student/LatestDrivingStudents";

export function Dashboard(): ReactElement | null {
  const [{ drivingSchoolId }] = useAutovioContext();
  const { record: drivingSchool } = useShowController({
    resource: "drivingSchools",
    id: drivingSchoolId,
    queryOptions: { enabled: !!drivingSchoolId },
  });

  if (!drivingSchoolId) {
    if (restrictAccessToDrivingSchoolIds) {
      return (
        <div className="centered">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            Bitte wähle eine Fahrschule aus.
          </div>
        </div>
      );
    }

    return (
      <>
        <PageTitle>AUTOVIO Fahrschulen</PageTitle>
        <Card sx={{ mt: "10px", height: "calc(100% - 66px)" }}>
          <AutovioMap />
        </Card>
      </>
    );
  }

  if (!drivingSchool) {
    return null;
  }

  return (
    <RecordContextProvider value={drivingSchool}>
      <PageTitle>Dashboard</PageTitle>
      <Box sx={{ display: "flex", gap: "30px" }}>
        <Box>
          <LatestDrivingStudents />
        </Box>
        <Card sx={{ width: "100%", border: "1px solid #e5e5e5", padding: "24px 30px 30px 30px", borderRadius: "12px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 15, mb: "19px" }}>Verteilung Fahrschüler</Typography>
          <DrivingSchoolMap />
        </Card>
      </Box>
    </RecordContextProvider>
  );
}
