import {
  BooleanField,
  Datagrid,
  FunctionField,
  NumberField,
  TextField,
  useGetOne,
  useListContext,
  useRecordContext,
} from "react-admin";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { ShowButton } from "../buttons/ShowButton";
import { StudentBranchField } from "../fields/StudentBranchField";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { MoneyField } from "../fields/MoneyField";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { DateField } from "../fields/DateField";
import { Avatar, Box, Typography } from "@mui/material";
import { Student } from "../providers/studentsProvider";
import { dotStyle, studentStatusColors } from "../misc/backofficeTheme";
import TimelineDot from "@mui/lab/TimelineDot";

export function StudentsDataGrid(props: { drivingSchool?: DrivingSchool }) {
  const { isLoading: isLoading1 } = useListContext();
  const { data, isLoading: isLoading2 } = useGetOne<DrivingSchool>(
    "drivingSchools",
    { id: restrictAccessToDrivingSchoolIds?.[0] ?? "" },
    { enabled: !props.drivingSchool && restrictAccessToDrivingSchoolIds?.length === 1 },
  );

  const isLoading = isLoading1 || isLoading2;
  const drivingSchool = props.drivingSchool || data;

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Datagrid rowClick="edit" bulkActionButtons={false} className="students-list">
      <FunctionField label="Name" source="name" render={_renderAvatarAndName} />
      {drivingSchool && drivingSchool.branches.length > 1 && (
        <StudentBranchField label="Filiale" drivingSchoolId={drivingSchool.id} />
      )}
      <BooleanField label="Wechsel" source="isSchoolChanger" FalseIcon={null} />
      <TextField label="Ausbildung" source="bookedTrainingsSummary" />
      <DateField label="Anmeldung" source="startDate" />
      <NumberField label="TL." source="kpis.finishedTheoryLessonsCount" />
      <NumberField label="ÜS." source="kpis.finishedNormalDrivingLessonsCount" options={{ maximumFractionDigits: 1 }} />
      <NumberField
        label="SF."
        source="kpis.finishedSpecialDrivingLessonsCount"
        options={{ maximumFractionDigits: 1 }}
      />
      <_StatusField label="Status" source="verboseStatus" />
      <MoneyField label="Saldo" source="balance" redForNegativeValues />
      <ShowButton label="" />
    </Datagrid>
  );
}

export function _StatusField({ label, source }: { label: string; source: string }) {
  const student = useRecordContext<Student>();
  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
      <TimelineDot sx={{ ...dotStyle, backgroundColor: studentStatusColors[student.verboseStatus] }} />
      <TextField label={label} source={source} />
    </Box>
  );
}

function _renderAvatarAndName(student: Student) {
  return (
    <Box sx={{ display: "flex", gap: "15px", alignItems: "center", marginLeft: "16px" }}>
      <Avatar src={student?.avatarOverrideUrl ?? student?.avatarUrl} />
      <Typography sx={{ fontSize: 14 }}>{student.name}</Typography>
    </Box>
  );
}
