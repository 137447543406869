import { collection, getDocsFromServer, query, where } from "firebase/firestore";
import { CreateParams, CreateResult, GetManyReferenceParams, GetManyReferenceResult, Identifier } from "react-admin";
import { Document } from "../model/Document";
import { firestore } from "../firebase";
import { applyFilter, applyPagination, applySort, escapeHtml, reportError } from "../backoffice.utils";
import { Attachment, AttachmentSchema } from "../model/Attachment";
import { gcs } from "../utils/storage";
import { studentsProvider } from "./studentsProvider";
import { authProvider } from "../backoffice.access_control";
import { t } from "../model/types";
import { SignatureRequestSchema } from "../model/SignatureRequest";
import capitalize from "lodash/capitalize";
import { v4 as uuid } from "uuid";
import { createNote } from "../api/backoffice.api";
import { studentNotesProvider } from "./notesProvider";
import { LocalizedError } from "../utils/LocalizedError";
import { determineMimeType } from "../utils/determineMimeType";
import { getFileNameExtension } from "../utils/getFileNameExtension";
import { AbstractProvider } from "./abstractProvider";
import { getFileNameExtensionForMimeType } from "../utils/getFileNameExtensionForMimeType";

abstract class AbstractDocumentsProvider extends AbstractProvider<Document> {
  private _snapshotPromiserecordId: Identifier | undefined;
  private _snapshotPromise: Promise<Array<Document>> | undefined;

  constructor(private readonly resource: "studentDocuments" | "drivingSchoolDocuments") {
    super();
  }

  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Document>> {
    if (this.resource === "studentDocuments" && target !== "studentUid") {
      throw new Error(`Unexpected target: ${JSON.stringify(target)} -- expected: "studentUid"`);
    }
    const documents = await this.fetchIfNeeded(id);
    return applyPagination(applySort(applyFilter(documents, filter), sort), pagination);
  }

  async create(
    _: string,
    {
      data,
    }: CreateParams<{
      recordId: string;
      files: Array<{ rawFile: File }>;
    }>,
  ): Promise<CreateResult<Document>> {
    const { recordId, files } = data;
    if (!recordId) {
      if (this.resource === "studentDocuments") {
        throw new LocalizedError(`Kein Fahrschüler ausgewählt.`);
      }
      throw new LocalizedError(`Keine Fahrschule ausgewählt.`);
    }
    if (!files || files.length === 0) {
      throw new LocalizedError("Keine Datei ausgewählt.");
    }
    const emptyFile = files.find((it) => it.rawFile.size === 0);
    if (emptyFile) {
      throw new LocalizedError(`Die Datei ${emptyFile.rawFile.name} ist leer.`);
    }
    const knownIds = new Set((await this.fetchIfNeeded(recordId)).map((document) => document.id));

    // 1. Determine and validate MIME types ...
    const mimeTypes: Array<string> = [];
    for (let i = 0; i < files.length; ++i) {
      const file = files[i].rawFile;
      const extension = getFileNameExtension(file.name);
      const mimeType = await determineMimeType(file);
      if (extension === "pdf" && mimeType !== "application/pdf") {
        throw new LocalizedError(`Die Datei ${file.name} ist keine gültige PDF-Datei.`);
      }
      mimeTypes[i] = mimeType || file.type || "application/octet-stream";
    }

    // 2. Upload files to Google Cloud Storage ...
    const attachments: Array<Omit<Defined<Attachment, "size">, "id" | "lastChanged">> = [];
    for (let i = 0; i < files.length; ++i) {
      const file = files[i].rawFile;
      const mimeType = mimeTypes[i];
      let fileName = file.name;
      let extension = getFileNameExtension(file.name);
      // Add extension to file name if file name has no extension ...
      if (!extension) {
        extension = getFileNameExtensionForMimeType(mimeType);
        if (extension) {
          fileName = `${fileName}.${extension}`;
        }
      }
      attachments.push({
        name: fileName,
        path: (await gcs.uploadFile(file, `/uploads/${uuid()}/${fileName}`)).ref.fullPath,
        size: file.size,
        mimeType,
      });
    }

    // 3. Link uploads to student ...
    const { fullName } = (await authProvider.getIdentity?.()) ?? {};
    let noteBody: string;
    if (attachments.length === 1) {
      if (fullName) {
        noteBody = `<p>${escapeHtml(fullName)} hat das Dokument ${escapeHtml(attachments[0].name)} hinzugefügt.</p>`;
      } else {
        noteBody = `<p>Das Dokument ${escapeHtml(attachments[0].name)} wurde hinzugefügt.</p>`;
      }
    } else {
      if (fullName) {
        noteBody = `<p>${escapeHtml(fullName)} hat folgende Dokument hinzugefügt:</p><ul>`;
      } else {
        noteBody = "<p>Folgende Dokumente wurden hinzugefügt:</p>";
      }
      noteBody += "<ul>" + attachments.map((it) => `<li>${escapeHtml(it.name)}</li>`).join("") + "</ul>";
    }
    await createNote({ studentUid: recordId, body: noteBody, attachments });
    await studentNotesProvider.fetch(recordId);

    const documents = await this.fetch(recordId);
    const newDocument = documents.find((it) => !knownIds.has(it.id) && it.fileName === attachments[0].name)!;
    return { data: newDocument };
  }

  fetchIfNeeded(recordId: Identifier): Promise<Array<Document>> {
    if (this._snapshotPromiserecordId === recordId) {
      return this._snapshotPromise!;
    }
    return this.fetch(recordId);
  }

  fetch(recordId: Identifier): Promise<Array<Document>> {
    this._snapshotPromiserecordId = recordId;
    this._snapshotPromise = this._fetch(recordId);
    return this._snapshotPromise;
  }

  private async _fetch(recordId: Identifier): Promise<Array<Document>> {
    const documents = (
      await Promise.all([
        this.retrieveAttachments(recordId),
        this.retrieveDocuments(recordId),
        this.retrieveSignedDocuments(recordId),
      ])
    ).flat();
    console.info(`Retrieved ${documents.length} document(s) for student ${recordId}`);
    return documents;
  }

  private async retrieveAttachments(recordId: Identifier): Promise<Array<Document>> {
    const path =
      this.resource === "studentDocuments"
        ? `/users/${recordId}/attachments`
        : `/driving_schools/${recordId}/attachments`;
    try {
      const snapshot = await getDocsFromServer(collection(firestore, path));
      const attachments = snapshot.docs.map((doc) => AttachmentSchema.parse(doc.data()));
      return attachments.map((attachment) => ({
        id: attachment.path,
        fileName: attachment.name,
        contentType: attachment.mimeType,
        createdAt: attachment.lastChanged,
        getDownloadUrl: () => gcs.getDownloadUrl(attachment.path),
      }));
    } catch (error) {
      reportError(`retrieveAttachments("${recordId}") failed`, error);
      return [];
    }
  }

  private async retrieveDocuments(recordId: Identifier): Promise<Array<Document>> {
    if (this.resource === "drivingSchoolDocuments") {
      return [];
    }
    try {
      const { data: student } = await studentsProvider.getOne("students", { id: recordId });
      const files = await gcs.listFiles(`/backend/documents/${student.autovioUserId}/${student.drivingSchoolId}`);
      return files
        .filter((it) => it.name.endsWith(".pdf"))
        .map((file) => ({
          id: file.fullPath,
          fileName: file.name,
          contentType: "application/pdf",
          createdAt: t.dateTime().parse(file.timeCreated),
          getDownloadUrl: () => gcs.getDownloadUrl(file),
        }));
    } catch (error) {
      reportError(`retrieveDocuments("${recordId}") failed`, error);
      return [];
    }
  }

  private async retrieveSignedDocuments(studentUid: Identifier): Promise<Array<Document>> {
    if (this.resource === "drivingSchoolDocuments") {
      return [];
    }
    try {
      const snapshot = await getDocsFromServer(
        query(collection(firestore, "/signature_requests"), where("studentUid", "==", studentUid)),
      );
      const signatureRequest = snapshot.docs.map((doc) => SignatureRequestSchema.parse({ uid: doc.id, ...doc.data() }));
      const completedSignatureRequests = signatureRequest.filter((it) => it.state === "completed");
      return completedSignatureRequests.map((it) => ({
        id: it.signedDocumentStorageRef!,
        fileName: `${capitalize(it.type)}.pdf`,
        contentType: "application/pdf",
        createdAt: it.studentSignedAt!,
        getDownloadUrl: () => gcs.getDownloadUrl(it.signedDocumentStorageRef!),
      }));
    } catch (error) {
      reportError(`retrieveSignedDocuments("${studentUid}") failed`, error);
      return [];
    }
  }
}

class StudentDocumentsProvider extends AbstractDocumentsProvider {}
class DrivingSchoolDocumentsProvider extends AbstractDocumentsProvider {}

export const studentDocumentsProvider = new StudentDocumentsProvider("studentDocuments");
export const drivingSchoolDocumentsProvider = new DrivingSchoolDocumentsProvider("drivingSchoolDocuments");
export type DocumentsSource = "studentDocuments" | "drivingSchoolDocuments";
