export function StudentSearchIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 4.425a1.575 1.575 0 1 1 0 3.15 1.575 1.575 0 0 1 0-3.15m0 6.75c2.227 0 4.575 1.095 4.575 1.575v.825h-9.15v-.825c0-.48 2.348-1.575 4.575-1.575M9 3a3 3 0 1 0 0 6 3 3 0 1 0 0-6zm0 6.75c-2.003 0-6 1.005-6 3V15h12v-2.25c0-1.995-3.998-3-6-3z"
        fill="#888"
        fill-rule="evenodd"
      />
    </svg>
  );
}
