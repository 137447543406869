import { Box, Typography } from "@mui/material";
import { ListBase, Pagination, useGetRecordId, useRecordContext } from "react-admin";
import { CreateManualInvoiceButton } from "../../buttons/CreateManualInvoiceButton";
import { InvoicesList } from "../../lists/InvoicesList";
import { DunningButtons } from "../../buttons/DunningButtons";
import { Row } from "../../misc/Row";
import type { Student } from "../../providers/studentsProvider.js";
import { LoadingIndicator } from "../../misc/LoadingIndicator.js";

export function StudentInvoicesList() {
  const studentId = useGetRecordId();
  const student = useRecordContext<Student>();
  if (!student) {
    return <LoadingIndicator />;
  }
  return (
    <ListBase resource="b2cInvoices" filter={{ studentId }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 19, mb: "20px" }}>Rechnungen</Typography>
        <Row spacing={1}>
          <DunningButtons />
          <CreateManualInvoiceButton />
        </Row>
      </Box>
      <div>
        <InvoicesList student={student} />
        <Pagination />
      </div>
    </ListBase>
  );
}
