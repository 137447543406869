import {
  Datagrid,
  LinearProgress,
  ListContextProvider,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useGetOne,
  useList,
  useListContext,
  useRecordContext,
} from "react-admin";
import { DateField } from "../../fields/DateField";
import React, { Fragment, type ReactNode } from "react";
import { BackendUserRecord } from "../../providers/backendUserProvider";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { type MonetaryFlowEnum, User } from "../../generated/backendClient";
import { grants } from "../../backoffice.access_control";
import { GiftMoneyButton } from "../../buttons/GiftMoneyButton";
import { GiftVoucherButton } from "../../buttons/GiftVoucherButton";
import { Row } from "../../misc/Row";
import { GiftVoucher } from "../../providers/giftVouchersProvider";
import { RefundMultipleDialogButton } from "../../buttons/RefundMultipleDialogButton";
import { overviewCardStyle } from "../../misc/backofficeTheme";

export function StudentCredits() {
  return (
    <Box sx={{ ...overviewCardStyle, margin: "20px -20px 0 -20px" }}>
      <ReferenceField reference="backendUser" source="id" label="">
        <StudentAccounts />
      </ReferenceField>
    </Box>
  );
}

const StudentAccounts = () => {
  const record: BackendUserRecord = useRecordContext();
  const studentId = record.payload.firebase_auth_users[0];
  const monetaryFlow = record.payload.monetary_flow;
  return (
    <>
      {Object.entries(record.payload.accounts).map(([drivingSchoolId, accounts]) => (
        <Fragment key={drivingSchoolId}>
          <Box position="relative" style={{ padding: "20px" }}>
            <Row spacing={1} style={{ minHeight: "48px" }}>
              {grants.includes("giftCredits") && (
                <GiftMoneyButton drivingSchoolId={drivingSchoolId} studentId={studentId} />
              )}
              {grants.includes("giftVouchers") && <GiftVoucherButton studentId={studentId} />}
              {grants.includes("refundPayments") && <RefundMultipleDialogButton studentId={studentId} />}
            </Row>
          </Box>
          <span key={`accounts-${drivingSchoolId}`}>
            {Object.keys(record.payload.accounts).length > 1 ? renderDrivingSchoolName(drivingSchoolId) : ""}
            {renderDrivingSchoolAccounts(accounts, monetaryFlow)}
          </span>
        </Fragment>
      ))}
    </>
  );
};

// infer type information from the generated backendClient (all accounts have the same structure, so we just pick the first one)
type AccountInfo = User["accounts"][0]["balance"];

const moneyForNumberField = (money: AccountInfo["balance"]): { amount: number; currency: string } => {
  return { amount: parseFloat(money.amount), currency: money.currency };
};

const TransactionList = ({ transactions }: { transactions: AccountInfo["transactions"] }) => {
  const listContext = useList({
    data: transactions.map((t) => {
      return {
        ...t,
        amount: moneyForNumberField(t.amount),
        description: t.description,
      };
    }),
  });
  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        bulkActionButtons={false}
        isRowSelectable={() => false}
        empty={<Typography>Keine Transaktionen gefunden.</Typography>}
      >
        <DateField source="created_at" label="Datum" />
        <NumberField source="amount.amount" label="Betrag" options={{ style: "currency", currency: "EUR" }} />
        <TextField source="description" label="Beschreibung" />
        {/*<TextField source="payload" />*/}
      </Datagrid>
    </ListContextProvider>
  );
};

const AccountBalance = ({
  title,
  accountInfo,
  children,
}: {
  title: string;
  accountInfo?: AccountInfo;
  children?: ReactNode;
}) => {
  return (
    <Card sx={{ marginTop: "1em" }}>
      <CardContent>
        <span style={{ fontWeight: 900 }}>{title}</span>
        <br />
        {children ? (
          children
        ) : accountInfo ? (
          <>
            <NumberField
              source="amount"
              record={{ amount: parseFloat(accountInfo.balance.amount) }}
              options={{ style: "currency", currency: accountInfo.balance.currency }}
              style={{ fontWeight: 900, fontSize: "1.5rem" }}
            />
            <br />
            <TransactionList transactions={accountInfo.transactions} />
          </>
        ) : (
          <>
            <span>Keine Transaktionen</span>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const renderDrivingSchoolName = (drivingSchoolId: string) => {
  const { data: drivingSchool, isLoading } = useGetOne<DrivingSchool>("drivingSchools", { id: drivingSchoolId });
  return <h5 key={drivingSchoolId}>Konten bei {isLoading ? drivingSchoolId : drivingSchool?.name}</h5>;
};

type DrivingSchoolAccounts = BackendUserRecord["payload"]["accounts"][0];

const renderDrivingSchoolAccounts = (accounts: DrivingSchoolAccounts, monetaryFlow: MonetaryFlowEnum) => {
  return (
    <>
      <AccountBalance key="prepaid" title="Prepaid/Vorauszahlungen" accountInfo={accounts.prepaid_credits} />
      {monetaryFlow === "TRANSFER" ? (
        <AccountBalance key="contingents" title="Verschenktes Guthaben" accountInfo={accounts.credits_contingents} />
      ) : (
        <>
          <AccountBalance key="freecredits" title="Verschenktes Guthaben" accountInfo={accounts.free_credits} />
          <AccountBalance key="startcredits" title="Start Credits" accountInfo={accounts.start_credits} />
          <AccountBalance key="balance" title="Saldo" accountInfo={accounts.balance} />
        </>
      )}
      <AccountBalance key="giftVouchers" title="Verschenkte Gutscheine">
        <ReferenceManyField
          reference="giftVouchers"
          target="beneficiary"
          sort={{ field: "createdAt", order: "DESC" }}
          page={1}
          perPage={9999}
        >
          <_GiftVouchersList />
        </ReferenceManyField>
      </AccountBalance>
    </>
  );
};

function _GiftVouchersList() {
  const { data } = useListContext<GiftVoucher>();

  if (!data) {
    return <LinearProgress />;
  }

  const totalAmount = data.reduce((sum, voucher) => sum + parseFloat(voucher.amount), 0);

  return (
    <>
      <NumberField
        source="amount"
        record={{ amount: totalAmount }}
        options={{ style: "currency", currency: "EUR" }}
        style={{ fontWeight: 900, fontSize: "1.5rem" }}
      />
      <br />
      <Datagrid
        bulkActionButtons={false}
        isRowSelectable={() => false}
        empty={<Typography>Keine Transaktionen gefunden.</Typography>}
      >
        <DateField source="createdAt" label="Datum" />
        <NumberField source="amount" label="Betrag" options={{ style: "currency", currency: "EUR" }} />
        <TextField source="description" label="Beschreibung" />
      </Datagrid>
    </>
  );
}
