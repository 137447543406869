import * as Sentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";
import { createBrowserHistory } from "history";
import * as React from "react";
import { useEffect, useState } from "react";
import * as ReactDOM from "react-dom/client";
import { Admin, AuthContext, ThemeProvider } from "react-admin";
import { reportError } from "./backoffice.utils";
import { Dashboard } from "./misc/Dashboard";
import { compositeDataProvider } from "./providers/compositeDataProvider";
import {
  authProvider,
  currentUser,
  grants,
  initAccessControl,
  restrictAccessToDrivingSchoolIds,
  setUpVisualTest,
} from "./backoffice.access_control";
import { backofficeTheme } from "./misc/backofficeTheme";
import { i18nProvider } from "./l10n";
import { LoginPage } from "./misc/LoginPage";
import { AccessDeniedPage } from "./misc/AccessDeniedPage";
import { initializeFirebase } from "./firebase";
import { BackofficeLayout } from "./misc/BackofficeLayout";
import { RecoilRoot, useRecoilValue } from "recoil";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { deDE } from "@mui/x-date-pickers/locales";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Settings } from "luxon";
import { isExpectedError } from "./utils/isExpectedError";
import { drivingSchoolsProvider } from "./providers/drivingSchoolsProvider";
import { instructorsProvider } from "./providers/instructorsProvider";
import { studentsProvider } from "./providers/studentsProvider";
import { adasResource } from "./resources/adasResource";
import { areasResource } from "./resources/areasResource";
import { autovioEmployeesResource } from "./resources/autovioEmployeesResource";
import { bundlesResource } from "./resources/bundlesResource";
import { businessIntelligenceResource } from "./resources/businessIntelligenceResource";
import { drivingSchoolsResource } from "./resources/drivingSchoolsResource";
import { dunningResource } from "./resources/dunningResource";
import { instructorsResource } from "./resources/instructorsResource";
import { invoiceCustomersResource } from "./resources/invoiceCustomersResource";
import { productsResource } from "./resources/productsResource";
import { promoCodesResource } from "./resources/promoCodesResource";
import { studentsResource } from "./resources/studentsResource";
import { trainingsResource } from "./resources/trainingsResource";
import { usersToBeDeletedResource } from "./resources/usersToBeDeletedResource";
import { vehiclesResource } from "./resources/vehiclesResource";

import "@fontsource/poppins/600.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/400.css";
import "./css/main.css";
import "./css/calendar.css";
import "./css/dialogs.css";
import "./css/new_design.css";
import "./css/tailwind.css";
import { appliedReferralCodesResource } from "./resources/appliedReferralCodesResource";
import { rerenderCounterState } from "./hooks/useRerender";
import { persistentAutovioContext } from "./persistent_state";
import { AbstractProvider } from "./providers/abstractProvider";

Settings.defaultZone = "Europe/Berlin";

async function main() {
  try {
    if (location.hostname === "backoffice.autovio.de") {
      Sentry.init({
        dsn: "https://eec770aec08c455195df6055d04118a8@o956470.ingest.sentry.io/5905853",
        integrations: [
          Sentry.browserTracingIntegration(),
          captureConsoleIntegration({ levels: ["warn", "error"] }),
          Sentry.replayIntegration({
            blockAllMedia: false,
            maskAllInputs: false,
            maskAllText: false,
          }),
        ],
        tracesSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        release: "2024-10-11-26d448fe",
        beforeBreadcrumb: (breadcrumb, hint) => {
          let newBreadcrumb = breadcrumb;
          if (breadcrumb.category === "ui.click") {
            newBreadcrumb = {
              ...breadcrumb,
              message: `Element: ${hint?.event.srcElement.localName} | innerText: ${
                hint?.event.srcElement.innerText ?? "undefined"
              } | ariaLabel: ${hint?.event.srcElement.ariaLabel}${
                hint?.event.srcElement.placeholder && ` | placeholder: ${hint?.event.srcElement.placeholder}`
              }`,
            };
          }
          return newBreadcrumb;
        },
        beforeSend: (event, hint) => {
          // Don't report expected errors ...
          return isExpectedError(event, hint) ? null : event;
        },
      });
    }
    window.browserHistory = createBrowserHistory();
    const isVisualTest = location.search.includes("visualTest");
    if (isVisualTest) {
      await setUpVisualTest();
      const urlSearchParams = new URLSearchParams(window.location.href);
      const dataSnapshotFileName = urlSearchParams.get("dataSnapshot");
      const { DataSnapshotDataProvider } = await import("./backoffice.test_utils");
      try {
        window.dataProvider = dataSnapshotFileName
          ? await DataSnapshotDataProvider.create(dataSnapshotFileName)
          : new AbstractProvider();
      } catch (error) {
        console.error("Failed to create data provider", error);
        return;
      }
    } else {
      await initializeFirebase();
      const { currentUser, grants } = await initAccessControl();
      window.dataProvider = compositeDataProvider;
      if (currentUser && grants.length > 0) {
        await Promise.all([drivingSchoolsProvider.preload(), instructorsProvider.preload()]);
        void studentsProvider.preload();
      }
      // I have no idea how to configure the redirect URI for the Google login,
      // therefore I just patch the history here, when the user has been authenticated
      // by Google and was redirected to '/login' ...
      if (currentUser && browserHistory.location.pathname === "/login") {
        browserHistory.replace("/");
      }
      browserHistory.listen(({ action, location }: { action: "PUSH" | "POP" | "REPLACE"; location: Location }) => {
        console.info(`Navigation: ${action} ${location.pathname}`);
        window.onNavigation?.();
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = ReactDOM.createRoot(document.getElementById("root")!);
    root.render(
      <RecoilRoot>
        <_VerifyAutovioContext>
          <Backoffice />
        </_VerifyAutovioContext>
      </RecoilRoot>,
    );
  } catch (error) {
    reportError("main() failed", error);
  }
}

void main();

function Backoffice() {
  const rerenderCounter = useRecoilValue(rerenderCounterState);
  const visibleResources = [];
  visibleResources.push(drivingSchoolsResource);
  visibleResources.push(instructorsResource);
  visibleResources.push(studentsResource);
  if (grants.includes("viewDunning")) {
    visibleResources.push(dunningResource);
  }
  if (grants.includes("viewInvoiceCustomers")) {
    visibleResources.push(invoiceCustomersResource);
  }
  if (grants.includes("viewAreas") && restrictAccessToDrivingSchoolIds?.length !== 1) {
    visibleResources.push(areasResource);
  }
  if (grants.includes("viewProducts")) {
    visibleResources.push(productsResource);
  }
  if (grants.includes("viewTrainings")) {
    visibleResources.push(trainingsResource);
  }
  if (grants.includes("viewBundles") && restrictAccessToDrivingSchoolIds?.length !== 1) {
    visibleResources.push(bundlesResource);
  }
  if (grants.includes("viewPromoCodes")) {
    visibleResources.push(promoCodesResource);
  }
  if (grants.includes("viewAppliedReferralCodes")) {
    visibleResources.push(appliedReferralCodesResource);
  }
  visibleResources.push(vehiclesResource);
  if (grants.includes("viewADAS")) {
    visibleResources.push(adasResource);
  }
  if (grants.includes("viewAutovioEmployees")) {
    visibleResources.push(autovioEmployeesResource);
  }
  if (grants.includes("viewUsersToBeDeleted")) {
    visibleResources.push(usersToBeDeletedResource);
  }
  if (
    grants.includes("viewAutovioBusinessIntelligenceData") ||
    grants.includes("viewDrivingSchoolBusinessIntelligenceData")
  ) {
    visibleResources.push(businessIntelligenceResource);
  }
  if (!currentUser) {
    return (
      <AuthContext.Provider value={authProvider}>
        <ThemeProvider theme={backofficeTheme}>
          <LoginPage />
        </ThemeProvider>
      </AuthContext.Provider>
    );
  }
  if (currentUser && grants.length === 0) {
    return (
      <AuthContext.Provider value={authProvider}>
        <ThemeProvider theme={backofficeTheme}>
          <AccessDeniedPage />
        </ThemeProvider>
      </AuthContext.Provider>
    );
  }
  return (
    <LocalizationProvider
      localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
      dateAdapter={AdapterLuxon}
      adapterLocale="de"
    >
      <Admin
        key={rerenderCounter}
        theme={backofficeTheme}
        layout={BackofficeLayout}
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        history={browserHistory}
        loginPage={LoginPage}
        disableTelemetry
      >
        {visibleResources}
      </Admin>
    </LocalizationProvider>
  );
}

function _VerifyAutovioContext({ children }: { children: React.ReactNode }) {
  const { drivingSchoolId } = useRecoilValue(persistentAutovioContext);
  const [verified, setVerified] = useState(false);
  useEffect(() => {
    void (async () => {
      if (drivingSchoolId) {
        const drivingSchool = drivingSchoolsProvider.getOneFromCache(drivingSchoolId);
        if (!drivingSchool) {
          console.info(`Unknown driving school in autovioContext: ${drivingSchoolId} -- clearing storage ...`);
          let storageCleared = false;
          try {
            await _clearStorage();
            storageCleared = true;
          } catch (error) {
            console.error("Failed to clear storage", error);
          }
          if (storageCleared) {
            console.info("Reloading page ...");
            location.reload();
          }
        }
      }
      setVerified(true);
    })();
  }, []);
  return verified ? children : null;
}

async function _clearStorage(): Promise<void> {
  localStorage.clear();
  sessionStorage.clear();
  for (const db of await indexedDB.databases()) {
    const dbName = db.name!;
    await new Promise((resolve, reject) => {
      const request = indexedDB.deleteDatabase(dbName);
      request.onsuccess = resolve;
      request.onerror = (_) => reject(new Error(`Failed to delete database ${dbName}`));
    });
  }
}
