import { PaginationPayload, useGetList } from "react-admin";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { Avatar, Box, Card, Typography } from "@mui/material";
import { formatDateOrTime } from "../../utils/calendar";
import { Student } from "../../providers/studentsProvider";
import { Spinner } from "../../misc/Spinner";
import { BookedTraining } from "../../model/BookedTraining";

export function LatestDrivingStudents() {
  const [autovioContext] = useAutovioContext();

  const { data: students, isLoading } = useGetList<Student>("students", {
    filter: { drivingSchoolId: autovioContext.drivingSchoolId, hasPaidBaseFee: true },
    pagination: { page: 1, perPage: 10 } as PaginationPayload,
    sort: { field: "startDate", order: "DESC" },
  });

  function getBookedTrainingLabel(bookedTrainings: BookedTraining[]) {
    return bookedTrainings?.map((it) => it.shortLabel)?.join(", ");
  }

  if (isLoading) {
    return (
      <Card sx={cardStyles}>
        <Box className="centered">
          <Spinner style={{ marginTop: "60px" }} />
        </Box>
      </Card>
    );
  }

  return (
    <Card sx={cardStyles}>
      <Typography sx={{ fontWeight: "bold", fontSize: 15, mb: "19px" }}>Neueste Fahrschüler</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {students?.map((student) => (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "14px" }}>
              <Box>
                <Avatar src={student?.avatarUrl} sx={{ width: 32, height: 32 }} />
              </Box>
              <Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "normal",
                      fontSize: 14,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "160px",
                    }}
                  >
                    {student?.firstName} {student?.lastName}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "normal", fontSize: 11, color: "#888" }}>
                    {getBookedTrainingLabel(student.bookedTrainings)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography sx={{ fontWeight: "normal", fontSize: 14, color: "#888" }}>
                {formatDateOrTime(student.startDate!, true)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Card>
  );
}

const cardStyles = {
  width: "340px",
  height: "100%",
  background: "white",
  borderRadius: "12px",
  border: "1px solid #e5e5e5",
  padding: "24px 30px",
};
