import {
  collection,
  deleteField,
  doc,
  FieldValue,
  getDocsFromServer,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { DateTime } from "luxon";
import {
  FilterPayload,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  type GetOneParams,
  type GetOneResult,
  RaRecord,
  SortPayload,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { fetchFirebaseAuthUser } from "../api/backoffice.api";
import { grants, restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import {
  applyFilter,
  applyPagination,
  applySort,
  assertNever,
  classNameOf,
  fromFirestore,
  reportError,
  sleep,
  zodDateTime,
} from "../backoffice.utils";
import { firestore, functions } from "../firebase";
import { PostalAddress, PostalAddressSchema } from "../model/PostalAddress";
import { StudentStatus, StudentStatusEnum } from "../model/StudentStatus";
import { DateString, DateStringSchema } from "../model/DateString";
import { knownInvalidDocuments } from "./firestoreProvider";
import { z } from "zod";
import { InvoiceAddress, InvoiceAddressSchema } from "../model/InvoiceAddress";
import { getAuthenticatedServerClient } from "../api/server.api";
import { BookedTraining, bookedTrainingsFromFirestore } from "../model/BookedTraining";
import { Onboarding } from "../model/Onboarding";
import { FeatureTogglesForStudents } from "../model/FeatureToggles";
import { isMoney, Money } from "../model/Money";
import { Cents } from "../model/Cents";
import { t } from "../model/types";
import { updateApplied } from "../utils/updateApplied";
import { instructorsProvider } from "./instructorsProvider";
import { drivingSchoolsProvider } from "./drivingSchoolsProvider";
import { AbstractProvider } from "./abstractProvider";
import { QuerySnapshot } from "@firebase/firestore";
import {
  CAR_DRIVING_LICENSE_CLASSES,
  DrivingLicenseClass,
  MOTORCYCLE_DRIVING_LICENSE_CLASSES,
  TRAILER_DRIVING_LICENSE_CLASSES,
} from "../model/DrivingLicenseClass";
import { safeLocalStorage } from "../utils/safeLocalStorage";

export const ApprovalStatusEnum = z.enum(["pending", "approved", "notApproved"]);
export type ApprovalStatus = z.infer<typeof ApprovalStatusEnum>;

const AdministrativeOnboardingStateSchema = z.object({
  applicationApproved: z.optional(ApprovalStatusEnum).default("pending"),
  applicationFilledOut: z.optional(z.boolean()).default(false),
  applicationSent: z.optional(z.boolean()).default(false),
  applicationSubmitted: z.optional(z.boolean()).default(false),
  eyeTestDone: z.optional(z.boolean()).default(false),
  firstAidCourseBooked: z.optional(z.boolean()).default(false),
  firstAidCourseDone: z.optional(z.boolean()).default(false),
  firstAidCourseVoucherRequested: z.optional(z.boolean()).default(false),
  firstAidCourseVoucherSent: z.optional(z.boolean()).default(false),
  photoTaken: z.optional(z.boolean()).default(false),
});

type AdministrativeOnboardingState = z.infer<typeof AdministrativeOnboardingStateSchema>;

const AdministrativeOnboardingForChangersStateSchema = z.object({
  changeApproved: z.optional(ApprovalStatusEnum).default("pending"),
  changeSubmitted: z.optional(z.boolean()).default(false),
  oldContractTerminated: z.optional(z.boolean()).default(false),
  trainingCertificateSent: z.optional(z.boolean()).default(false),
});

type AdministrativeOnboardingForChangersState = z.infer<typeof AdministrativeOnboardingForChangersStateSchema>;

const StudentStatusHistorySchema = z.array(
  z.object({
    changedAt: zodDateTime(),
    changedBy: z.string(),
    newValue: StudentStatusEnum,
    reason: z.optional(z.string()),
  }),
);

interface TheoryKPIs {
  hasPassedTheoryExam?: boolean;
  theoryExams?: {
    [uid: string]: {
      result?: "passed" | "failed";
    };
  };
  theoryLearningProgress?: {
    numQuestions: number;
    numCorrectlyAnsweredQuestions: number;
    numIncorrectlyAnsweredQuestions: number;
  };
}

interface TheoryKPIs {
  theoryExams?: {
    [uid: string]: {
      result?: "passed" | "failed";
    };
  };
  theoryLearningProgress?: {
    numQuestions: number;
    numCorrectlyAnsweredQuestions: number;
    numIncorrectlyAnsweredQuestions: number;
  };
  finishedTheoryLessonsCount?: number;
}

const StudentDunningStatusEnum = z.enum([
  "warningSent",
  "paymentReminderSent",
  "firstDunningNoticeSent",
  "secondDunningNoticeSent",
  "handedOverToPairFinance",
]);

const DunningProcessSchema = z
  .object({
    id: z.string().uuid(),
    status: StudentDunningStatusEnum,
    paused: z.boolean(),
    outstandingAmount: z.number().nonnegative().int(),
    warningSentAt: z.optional(t.dateTime()),
    paymentReminderSentAt: z.optional(t.dateTime()),
    firstDunningNoticeSentAt: z.optional(t.dateTime()),
    secondDunningNoticeSentAt: z.optional(t.dateTime()),
    handedOverToPairFinanceAt: z.optional(t.dateTime()),
  })
  .transform((val) => (val && val.outstandingAmount === 0 ? null : val));

export type DunningProcess = z.infer<typeof DunningProcessSchema>;

export type VerboseStudentStatus =
  | "Abgebrochen"
  | "Aktiv"
  | "Aktiv (Grundbetrag offen)"
  | "Aktiv (Kein Zahlungsmittel)"
  | "Aktiv (Onboarding fehlt)"
  | "Fertig"
  | "Inaktiv"
  | "Offene Zahlungen"
  | "Pausiert"
  | "Unbekannt";

export interface Student {
  id: string;
  data: StudentDocumentData;
  autovioUserId: string;
  firstName: string;
  lastName: string;
  name: string;
  avatarUrl?: string;
  avatarOverrideUrl?: string;
  hubspotContactId?: string;
  applicationFeePercentage?: number;
  postalAddress: PostalAddress;
  status: StudentStatus | "";
  lastStatusChangeAt?: DateTime;
  administrativeOnboardingState?: AdministrativeOnboardingState;
  administrativeOnboardingForChangersState?: AdministrativeOnboardingForChangersState;
  drivingLicenseApplicationStatus?: ApprovalStatus;
  verboseStatus: VerboseStudentStatus;
  verboseStatusSinceXxxDays: string;
  instructorIds: Array<string>;
  drivingSchoolId: string;
  branchId?: string;
  dateOfBirth?: DateTime;
  startDate?: DateTime;
  kpis: {
    finishedTheoryLessonsCount: number;
    finishedDrivingLessonsCount: number;
    finishedNormalDrivingLessonsCount: number;
    finishedSpecialDrivingLessonsCount: number;
    finishedOtherDrivingLessonsCount: number;
    mostRecentDrivingLessonDate?: DateTime;
    mostRecentTheoryLessonDate?: DateTime;
    plannedTheoryExamDate?: DateTime;
    theoryExams: {
      [calenderEventUid: string]: {
        date: DateTime;
        result?: "passed" | "failed";
        rsvp?: "pending" | "accepted" | "rejected" | "declined" | "canceled" | "no-show";
      };
    };
  };
  bookedTrainings: Array<BookedTraining>;
  drivingLicenseClasses: Array<DrivingLicenseClass>;
  bookedTrainingsSummary: string;
  activeOrMostRecentBookedTraining: BookedTraining;
  onboardings: Array<Onboarding>;
  stripeCustomerId?: string;
  paymentStrategy: "upfrontPayment" | "payAsYouDrive" | "purchaseOnAccount";
  paymentMethod?: "card" | "sepa";
  hasPaidBaseFee: boolean;
  balance: Cents;
  budget: Cents | Money;
  theoryKPIs?: TheoryKPIs;
  maxBookableDrivingLessonsPerWeekLU?: number;
  maybeReadyForTheoryExam: boolean;
  isReadyForTheoryExam: boolean;
  hasPassedTheoryExam: boolean;
  isSchoolChanger: boolean;
  analyticsData?: {
    triedKlarnaPayment: boolean;
    successfulKlarnaPayment: boolean;
    paymentMethodType?: "card" | "sepa_debit" | "klarna";
    paymentMethodAddedAt?: Date | null;
  };
  bf17: boolean;
  needsGlasses: boolean;
  examinationAssignmentReceivedAt?: DateString | null;
  examinationAssignmentExpiresAt?: DateString | null;
  phoneNumber?: string;
  invoiceAddress?: InvoiceAddress;
  dunningProcess: DunningProcess | undefined | null;
  featureToggles: FeatureTogglesForStudents;
  guaranteedDrivingLessonsPerWeek: number;
}

export function isStudent(record: RaRecord): record is Student {
  return Array.isArray(record.bookedTrainings);
}

interface StudentDocumentData {
  authUserUids?: Array<string>; // <-- data model 2
  autovioUserId: string;
  hubspotContactId?: string;
  publicProfile: {
    firstName?: string;
    lastName?: string;
    avatarUrl?: string;
  };
  postalAddress?: Partial<PostalAddress>;
  myDrivingSchools?: Array<string>; // <-- data model 1
  currentDrivingSchoolId?: string; // <-- data model 2
  drivingSchoolIds?: Array<string>; // <-- data model 2
  myInstructors: Array<string>; // <-- data model 1
  instructorUids: Array<string>; // <-- data model 2
  onboardings?: {
    [quoteId: string]: {
      baseFee: number;
      baseFeePaid: boolean;
      quoteId: string;
    };
  };
  training?: {
    status: Exclude<StudentStatus, "completed">;
    startDate: string;
    kpis?: {
      finishedTheoryLessonsCount: number;
      finishedDrivingLessonsCount: Record<string, number>;
      mostRecentDrivingLessonDate?: Timestamp;
      mostRecentTheoryLessonDate?: Timestamp;
      plannedTheoryExamDate?: Timestamp;
      theoryExams: {
        [calenderEventUid: string]: {
          date: Timestamp;
          result?: "passed" | "failed";
        };
      };
      practicalExams: {
        [calenderEventUid: string]: {
          date: Timestamp;
          result?: "passed" | "failed";
        };
      };
    };
  };
  dateOfBirth?: string;
  stripeCustomerId?: string;
  paymentStrategy?: "upfrontPayment" | "payAsYouDrive" | "purchaseOnAccount";
  paymentMethod?: "card" | "sepa";
  hasPaidBaseFee?: boolean;
  balance?: Cents;
  budget?: Cents;
  theoryKPIs?: TheoryKPIs;
  maxBookableDrivingLessonsPerWeekLU?: number;
  myBranches?: Record<string, string>;
  analyticsData?: {
    triedKlarnaPayment: boolean;
    successfulKlarnaPayment: boolean;
    paymentMethodType?: "card" | "sepa_debit" | "klarna";
    paymentMethodAddedAt?: Timestamp;
  };
  phoneNumber?: string;
  maybeReadyForTheoryExam?: boolean;
  isReadyForTheoryExam?: boolean;
  invoiceAddress?: InvoiceAddress;
  bookedTrainings?: any;
  administrativeOnboardingState?: any;
  administrativeOnboardingForChangersState?: any;
  applicationFeePercentage?: number;
  bf17?: boolean | FieldValue;
  needsGlasses?: boolean | FieldValue;
  examinationAssignmentReceivedAt?: DateString | null;
  examinationAssignmentExpiresAt?: DateString | null;
  effectiveStatus?: StudentStatus; // <-- data model 2
  verboseStatus?: VerboseStudentStatus; // <-- data model 2
  dunningProcess?: any;
  featureToggles?: FeatureTogglesForStudents;
  studentStatusHistory?: any;
}

class StudentsProvider extends AbstractProvider<Student> {
  private readonly _studentsByIdByDrivingSchoolId: Map<string, Map<string, Student>> = new Map();
  private readonly _studentsByIdPromisesByDrivingSchoolId: Map<string, Promise<Map<string, Student>>> = new Map();
  private readonly _onUpdateListeners: Array<() => void> = [];

  private _webinarMode = safeLocalStorage.getItem("webinarMode", (s) => !!s);

  setWebinarMode(webinarMode: boolean): void {
    this._webinarMode = webinarMode;
    for (const map of this._studentsByIdByDrivingSchoolId.values()) {
      for (const student of map.values()) {
        this._convertStudent(student as any);
      }
    }
  }

  async preload(): Promise<void> {
    await this._fetchAll();
  }

  async getOne(_: string, { id }: GetOneParams): Promise<GetOneResult<Student>> {
    const student = this.getOneFromCache(id) ?? (await this._fetchOne(id));
    return { data: student };
  }

  getOneFromCache(id: string): Student | undefined {
    for (const studentsById of this._studentsByIdByDrivingSchoolId.values()) {
      const student = studentsById.get(id);
      if (student) {
        return student;
      }
    }
  }

  async getList(_resource: string, { filter, sort, pagination }: GetListParams): Promise<GetListResult<Student>> {
    let students = await this._getList(filter);
    if (!grants.includes("viewNonFullyActivatedStudents")) {
      students = students.filter(_fullyActivatedStudentsFilter);
    }
    return applyPagination(applySort(students, this._convertSort(sort)), pagination);
  }

  async _getList(filter: FilterPayload): Promise<Array<Student>> {
    const { drivingSchoolId, ...filter_ } = filter;
    if (drivingSchoolId) {
      if (typeof drivingSchoolId !== "string") {
        throw new Error(`Unexpected filter value for drivingSchoolId: ${classNameOf(drivingSchoolId)}`);
      }
      let students = this._studentsByIdByDrivingSchoolId.get(drivingSchoolId)?.values();
      if (!students) {
        students = (await this._fetchStudentsOfDrivingSchool(drivingSchoolId)).values();
      }
      return applyFilter(students, filter_);
    }
    if (filter.instructorIds) {
      const instructorIds: Array<string> = Array.isArray(filter.instructorIds)
        ? filter.instructorIds
        : typeof filter.instructorIds === "string"
        ? [filter.instructorIds]
        : (() => {
            throw new Error(`Unexpected filter value for instructorIds: ${classNameOf(filter.instructorIds)}`);
          })();
      const { data: instructors } = await instructorsProvider.getMany("instructors", { ids: instructorIds });
      if (!instructors.length) {
        return [];
      }
      const drivingSchoolId = instructors[0].drivingSchoolId;
      if (instructors.every((it) => it.drivingSchoolId === drivingSchoolId)) {
        return this._getList({ ...filter_, drivingSchoolId });
      }
    }
    return this._fetchAll(filter);
  }

  async getMany(_resource: string, { ids, meta }: GetManyParams): Promise<GetManyResult<Student>> {
    const drivingSchoolId =
      restrictAccessToDrivingSchoolIds?.length === 1
        ? restrictAccessToDrivingSchoolIds[0]
        : (meta ?? {}).drivingSchoolId;
    if (!drivingSchoolId) {
      let stack = new Error().stack ?? "\n";
      stack = stack.substring(stack.indexOf("\n"));
      console.info(
        `drivingSchoolId is missing in params.meta -- calling studentsProvider.getOne(...) ${ids.length} times ...`,
        stack,
      );
      const students = await Promise.all(ids.map((id) => this.getOne("students", { id }).then((r) => r.data)));
      return { data: students };
    }
    let studentsById = this._studentsByIdByDrivingSchoolId.get(drivingSchoolId);
    if (!studentsById) {
      studentsById = await this._fetchStudentsOfDrivingSchool(drivingSchoolId);
    }
    const students: Array<Student> = [];
    for (const id of ids) {
      const student = studentsById.get(id.toString());
      if (student) {
        students.push(student);
      }
    }
    return { data: students };
  }

  async getManyReference(
    resource: string,
    { target, id, pagination, sort, filter }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Student>> {
    return this.getList(resource, { pagination, sort, filter: { ...filter, [target]: id } });
  }

  async update(resource: string, update: UpdateParams<Student>): Promise<UpdateResult<Student>> {
    const updateData = Object.fromEntries(
      Object.entries(this.toFirestore(update.data, update.previousData)).filter(
        ([key, value]) => typeof value !== "undefined" && !["firstName", "lastName"].includes(key),
      ),
    );
    const updateReceived = new Promise<void>((resolve) => {
      const unsubscribe = this.onUpdate(async () => {
        const { data: student } = await this.getOne("students", { id: update.id });
        if (updateApplied(updateData, student.data)) {
          unsubscribe();
          resolve();
        }
      });
    });
    // Convert the nested fields to dot notation to avoid overwriting the entire publicProfile object.
    // See https://firebase.google.com/docs/firestore/manage-data/add-data#update_fields_in_nested_objects
    if (["firstName", "lastName"].some((key) => key in update.data)) {
      const firstName = update.data.firstName;
      const lastName = update.data.lastName;
      const autovioUserId = update.data.autovioUserId ?? update.previousData.autovioUserId;
      const serverClient = await getAuthenticatedServerClient();
      await serverClient.updateUser({ firstName, lastName }, { params: { id: autovioUserId } });
    }
    console.info(`Updating users/${update.id} ...`, updateData);
    await setDoc(doc(firestore, `users/${update.id}`), updateData, { merge: true });
    await updateReceived;
    return this.getOne(resource, { id: update.id });
  }

  onUpdate(listener: () => any): () => void {
    if (this._onUpdateListeners.indexOf(listener) >= 0) {
      throw new Error("listener already registered");
    }
    this._onUpdateListeners.push(listener);
    return () => {
      const i = this._onUpdateListeners.indexOf(listener);
      if (i < 0) {
        throw new Error("listener already unregistered");
      }
      this._onUpdateListeners.splice(i, 1);
    };
  }

  fromFirestore(snapshot: QueryDocumentSnapshot<any, any>): Student {
    const data = snapshot.data() as StudentDocumentData;
    const firstName = data.publicProfile.firstName ?? "???";
    const lastName = data.publicProfile.lastName ?? "???";
    const bookedTrainings = bookedTrainingsFromFirestore(data.bookedTrainings ?? {});
    const onboardings = Object.values(data.onboardings ?? {}) as Array<Onboarding>;
    const drivingSchoolId = data.currentDrivingSchoolId ?? (data.myDrivingSchools ?? [])[0];
    if (!drivingSchoolId) {
      throw new Error(`${snapshot.ref.path} has no driving school`);
    }
    const drivingSchool = drivingSchoolsProvider.getOneFromCache(drivingSchoolId);
    if (!drivingSchool) {
      throw new Error(`${snapshot.ref.path} has invalid driving school`);
    }
    const status = data.effectiveStatus || _effectiveStatus(data.training?.status, bookedTrainings);
    let lastStatusChangeAt: undefined | DateTime;
    if (status === "completed") {
      for (const training of bookedTrainings) {
        for (const practicalExam of Object.values(training.kpis?.practicalExams ?? {})) {
          if (practicalExam.result === "passed") {
            if (lastStatusChangeAt) {
              DateTime.max(lastStatusChangeAt, practicalExam.date);
            } else {
              lastStatusChangeAt = practicalExam.date;
            }
          }
        }
      }
    }
    if (!lastStatusChangeAt && data.studentStatusHistory) {
      try {
        const studentStatusHistory = StudentStatusHistorySchema.parse(data.studentStatusHistory);
        const lastEntry = studentStatusHistory.at(-1);
        if (lastEntry?.newValue === status) {
          lastStatusChangeAt = lastEntry.changedAt;
        }
      } catch (_) {
        // Ignored
      }
    }
    const theoryKPIs: TheoryKPIs = data.theoryKPIs ?? {};
    if (!theoryKPIs.hasPassedTheoryExam) {
      if (
        bookedTrainings.some((it) => it.hasPassedTheoryExam) ||
        (theoryKPIs.theoryExams && Object.values(theoryKPIs.theoryExams ?? {}).some((it) => it.result === "passed"))
      ) {
        theoryKPIs.hasPassedTheoryExam = true;
      }
    }

    const administrativeOnboardingState = data.administrativeOnboardingState
      ? AdministrativeOnboardingStateSchema.parse(data.administrativeOnboardingState)
      : undefined;
    const administrativeOnboardingForChangersState = data.administrativeOnboardingForChangersState
      ? AdministrativeOnboardingForChangersStateSchema.parse(data.administrativeOnboardingForChangersState)
      : undefined;

    const drivingLicenseApplicationStatus =
      administrativeOnboardingState?.applicationApproved ?? administrativeOnboardingForChangersState?.changeApproved;

    const guaranteedDrivingLessonsPerWeek = bookedTrainings.reduce((prev, it) => {
      if (!it.isFinished && it.guaranteedDrivingLessonsPerWeek) {
        return Math.max(prev, it.guaranteedDrivingLessonsPerWeek);
      }
      return prev;
    }, 0);

    let bookedTrainingsSummary = bookedTrainings.map((it) => it.shortLabel).join(",\u00A0");
    if (guaranteedDrivingLessonsPerWeek > 0) {
      bookedTrainingsSummary += `\u00A0(${guaranteedDrivingLessonsPerWeek}\u00A0UE/W)`;
    }

    const student = this._convertStudent({
      id: data.authUserUids ? data.authUserUids[0] : snapshot.id,
      data,
      autovioUserId: data.autovioUserId,
      administrativeOnboardingState,
      administrativeOnboardingForChangersState,
      drivingLicenseApplicationStatus,
      applicationFeePercentage: data.applicationFeePercentage,
      hubspotContactId: data.hubspotContactId,
      _firstName: firstName,
      _lastName: lastName,
      _name: `${firstName} ${lastName}`,
      avatarUrl: data.publicProfile?.avatarUrl,
      drivingSchoolId,
      branchId: (data.myBranches ?? {})[drivingSchoolId],
      instructorIds: data.myInstructors ?? data.instructorUids ?? [],
      status,
      lastStatusChangeAt,
      verboseStatus: "Unbekannt",
      verboseStatusSinceXxxDays: "...",
      bookedTrainingsSummary,
      _postalAddress: PostalAddressSchema.parse(data.postalAddress ?? {}),
      _invoiceAddress: data.invoiceAddress ? InvoiceAddressSchema.parse(data.invoiceAddress) : undefined,
      dateOfBirth: data.dateOfBirth ? DateTime.fromISO(data.dateOfBirth) : undefined,
      startDate: data.training?.startDate ? DateTime.fromISO(data.training?.startDate) : undefined,
      kpis: {
        finishedTheoryLessonsCount: data.training?.kpis?.finishedTheoryLessonsCount ?? 0,
        finishedDrivingLessonsCount: this.sumDrivingLessonsCount(
          ["normal", "ueberlandfahrt", "autobahnfahrt", "nachtfahrt", "praktischePruefung", "schaltkompetenz"],
          data.training?.kpis?.finishedDrivingLessonsCount,
        ),
        finishedNormalDrivingLessonsCount: this.sumDrivingLessonsCount(
          ["normal"],
          data.training?.kpis?.finishedDrivingLessonsCount,
        ),
        finishedSpecialDrivingLessonsCount: this.sumDrivingLessonsCount(
          ["ueberlandfahrt", "autobahnfahrt", "nachtfahrt"],
          data.training?.kpis?.finishedDrivingLessonsCount,
        ),
        finishedOtherDrivingLessonsCount: this.sumDrivingLessonsCount(
          ["praktischePruefung", "schaltkompetenz"],
          data.training?.kpis?.finishedDrivingLessonsCount,
        ),
        mostRecentDrivingLessonDate: fromFirestore(data.training?.kpis?.mostRecentDrivingLessonDate),
        mostRecentTheoryLessonDate: fromFirestore(data.training?.kpis?.mostRecentTheoryLessonDate),
        plannedTheoryExamDate: fromFirestore(data.training?.kpis?.plannedTheoryExamDate),
        theoryExams: fromFirestore(data.training?.kpis?.theoryExams ?? {}) as any,
      },
      bookedTrainings,
      drivingLicenseClasses: bookedTrainings.map((it) => it.drivingLicenseClass),
      activeOrMostRecentBookedTraining: bookedTrainings[0],
      onboardings,
      stripeCustomerId: data.stripeCustomerId,
      paymentStrategy: data.paymentStrategy ?? "payAsYouDrive",
      paymentMethod: data.paymentMethod,
      hasPaidBaseFee: data.hasPaidBaseFee ?? false,
      balance: data.balance ?? 0,
      budget: data.budget ?? 0,
      theoryKPIs,
      maxBookableDrivingLessonsPerWeekLU: data.maxBookableDrivingLessonsPerWeekLU,
      maybeReadyForTheoryExam: !!data.maybeReadyForTheoryExam,
      isReadyForTheoryExam: !!data.isReadyForTheoryExam,
      hasPassedTheoryExam: Object.values(data.theoryKPIs?.theoryExams ?? {}).some(({ result }) => result === "passed"),
      isSchoolChanger: bookedTrainings.some((it) => it.drivingSchoolChange),
      ...(data.analyticsData
        ? {
            analyticsData: {
              ...data.analyticsData,
              paymentMethodAddedAt: data.analyticsData.paymentMethodAddedAt
                ? data.analyticsData.paymentMethodAddedAt.toDate()
                : null,
            },
          }
        : {}),
      bf17: !!data.bf17,
      needsGlasses: !!data.needsGlasses,
      examinationAssignmentReceivedAt: DateStringSchema.nullish().parse(data.examinationAssignmentReceivedAt),
      examinationAssignmentExpiresAt: DateStringSchema.nullish().parse(data.examinationAssignmentExpiresAt),
      _phoneNumber: data.phoneNumber,
      dunningProcess: DunningProcessSchema.nullish().parse(data.dunningProcess),
      featureToggles: {
        // default values ...
        ..._defaultFeatureToggles(bookedTrainings),
        ...(data.featureToggles ?? {}),
        ...(drivingSchool.prohibitsTheoryLearningInTheApp ? { canLearnTheoryInMobileApp: false } : {}),
      },
      guaranteedDrivingLessonsPerWeek,
    });
    student.activeOrMostRecentBookedTraining = _inferActiveOrMostRecentBookedTraining(student);
    student.verboseStatus = data.verboseStatus || _renderVerboseStatus(student);
    student.verboseStatusSinceXxxDays = _renderVerboseStatusSinceXxxDays(student);
    if (student.isSchoolChanger) {
      if (student.administrativeOnboardingState) {
        delete student.administrativeOnboardingState; // <-- Hide the Tab "FÜHRERSCHEINANTRAG"
      }
      if (!student.administrativeOnboardingForChangersState) {
        student.administrativeOnboardingForChangersState = AdministrativeOnboardingForChangersStateSchema.parse({});
      }
    } else {
      if (!student.administrativeOnboardingState) {
        student.administrativeOnboardingState = AdministrativeOnboardingStateSchema.parse({});
      }
      if (student.administrativeOnboardingForChangersState) {
        delete student.administrativeOnboardingForChangersState; // <-- Hide the Tab "FAHRSCHULWECHSEL"
      }
    }
    return student;
  }

  private sumDrivingLessonsCount(types: string[], data?: Record<string, number>): number {
    if (!data || Object.keys(data).length === 0) {
      return 0;
    }

    return types.reduce((acc, type) => acc + (data[type] ?? 0), 0);
  }

  toFirestore(updateData: Partial<Student>, previousData: Student): Partial<StudentDocumentData> {
    const budgetInCents =
      updateData.budget && (isMoney(updateData.budget) ? updateData.budget.amount * 100 : updateData.budget);
    const hasPassedTheoryExam = (updateData.theoryKPIs ?? {}).hasPassedTheoryExam as boolean | undefined;
    const result: Partial<StudentDocumentData> = {
      administrativeOnboardingState: updateData.administrativeOnboardingState,
      administrativeOnboardingForChangersState: updateData.administrativeOnboardingForChangersState,
      examinationAssignmentReceivedAt: updateData.examinationAssignmentReceivedAt,
      examinationAssignmentExpiresAt: updateData.examinationAssignmentExpiresAt,
      ...(updateData.bf17 !== undefined ? { bf17: updateData.bf17 ? true : deleteField() } : {}),
      ...(updateData.needsGlasses !== undefined
        ? { needsGlasses: updateData.needsGlasses ? true : deleteField() }
        : {}),
      applicationFeePercentage: updateData.applicationFeePercentage,
      maxBookableDrivingLessonsPerWeekLU: updateData.maxBookableDrivingLessonsPerWeekLU,
      paymentStrategy: updateData.paymentStrategy,
      phoneNumber: updateData.phoneNumber,
      postalAddress: updateData.postalAddress,
      invoiceAddress: updateData.invoiceAddress,
      ...(Object.keys(updateData.featureToggles ?? {}).length > 0 ? { featureToggles: updateData.featureToggles } : {}),
      ...(budgetInCents !== undefined ? { budget: budgetInCents } : {}),
    };
    if (typeof hasPassedTheoryExam === "boolean") {
      result.theoryKPIs = { hasPassedTheoryExam };
      const bookedTrainingsUpdateData: any = {};
      for (const bookedTraining of previousData.bookedTrainings) {
        if (bookedTraining.drivingSchoolChange) {
          bookedTrainingsUpdateData[bookedTraining.id] = { hasPassedTheoryExam };
        }
      }
      if (Object.keys(bookedTrainingsUpdateData).length) {
        result.bookedTrainings = bookedTrainingsUpdateData;
      }
    }
    return result;
  }

  private async _fetchOne(id: string): Promise<Student> {
    let q = query(collection(firestore, "students"), where("authUserUids", "array-contains", id));
    if (restrictAccessToDrivingSchoolIds?.length === 1) {
      q = query(q, where("currentDrivingSchoolId", "==", restrictAccessToDrivingSchoolIds[0]));
    } else if (restrictAccessToDrivingSchoolIds) {
      q = query(q, where("currentDrivingSchoolId", "in", restrictAccessToDrivingSchoolIds));
    }
    const snapshot = await getDocsFromServer(q);
    if (snapshot.size === 0) {
      throw new Error(`Student ${id} not found`);
    }
    const student = this.fromFirestore(snapshot.docs[0]);
    return student;
  }

  private async _fetchAll(filter?: FilterPayload): Promise<Array<Student>> {
    // We don't need to check restrictAccessToDrivingSchoolIds here,
    // because this is already done in drivingSchoolsProvider ...
    const drivingSchools = (await drivingSchoolsProvider.snapshot()).records;
    const students = (
      await Promise.all(
        drivingSchools.map(async (drivingSchool) => {
          const studentsById = await this._fetchStudentsOfDrivingSchool(drivingSchool.id);
          return filter ? applyFilter(studentsById.values(), filter) : [...studentsById.values()];
        }),
      )
    ).flat();
    return students;
  }

  private _fetchStudentsOfDrivingSchool(drivingSchoolId: string): Promise<Map<string, Student>> {
    let promise = this._studentsByIdPromisesByDrivingSchoolId.get(drivingSchoolId);
    if (!promise) {
      promise = this.__fetchStudentsOfDrivingSchool(drivingSchoolId);
      this._studentsByIdPromisesByDrivingSchoolId.set(drivingSchoolId, promise);
    }
    return promise;
  }

  private async __fetchStudentsOfDrivingSchool(drivingSchoolId: string): Promise<Map<string, Student>> {
    if (restrictAccessToDrivingSchoolIds && !restrictAccessToDrivingSchoolIds.includes(drivingSchoolId)) {
      throw new Error(`Access to students of driving school ${drivingSchoolId} denied.`);
    }
    const {
      data: { name: drivingSchool },
    } = await drivingSchoolsProvider.getOne("drivingSchools", { id: drivingSchoolId });
    console.info(`Retrieving all students of ${drivingSchool} ...`);
    const query_ = query(collection(firestore, "students"), where("currentDrivingSchoolId", "==", drivingSchoolId));
    try {
      const t0 = Date.now();
      const firstSnapshot = await getDocsFromServer(query_);
      const studentsByIdFromServer = await this._convertSnapshotToStudentsById(firstSnapshot);
      console.info(`Retrieved ${studentsByIdFromServer.size} student(s) of ${drivingSchool} in ${Date.now() - t0}ms`);
      try {
        this._onNewSnapshot(drivingSchoolId, studentsByIdFromServer);
        return studentsByIdFromServer;
      } finally {
        let log = false; // <-- Don't log the first received snapshot
        let latestSnapshot: undefined | QuerySnapshot;
        let relax = false;
        onSnapshot(query_, async (newSnapshot) => {
          // Ignore incomplete snapshots by checking their length ...
          // From https://github.com/firebase/firebase-js-sdk/issues/5682:
          // "The first snapshot will be raised once any document content is available -
          // this might be before the server responds if there are any document in the cache
          // that match the query. This cache includes all currently active queries even if
          // the cache is only kept in memory (and you have not opted into disk persistence).
          // As the backend starts returning results, documents are then added to the next snapshot.
          // All documents will cause an ADDED change once, but it is correct that this can happen
          // over multiple snapshots."
          if (newSnapshot.docs.length < firstSnapshot.docs.length * 0.95) {
            return;
          }
          latestSnapshot = newSnapshot;
          // We wait a little bit here, to (1) Give the CPU time to do more important stuff
          // and (2) to see if another snapshot arrives in the meantime ...
          await sleep({ milliseconds: relax ? 1500 : 500 });
          const studentsById: Map<string, Student> = new Map();
          let timeout = Date.now() + 100;
          for (const doc of newSnapshot.docs) {
            try {
              if (latestSnapshot !== newSnapshot) {
                // Another snapshot has been received!
                relax = true;
                return;
              }
              const student = studentsProvider.fromFirestore(doc);
              studentsById.set(student.id, student);
            } catch (error) {
              const { path } = doc.ref;
              if (!knownInvalidDocuments.has(path)) {
                knownInvalidDocuments.add(path);
                console.info(`Failed to parse Firestore document ${path} -- ignoring it`, error);
              }
            }
            // Keep the browser responsive if the snapshot is large ...
            if (Date.now() >= timeout) {
              await sleep({ milliseconds: 1 });
              timeout = Date.now() + 100;
            }
          }
          if (log) {
            console.info(`Retrieved new snapshot with ${studentsById.size} student(s) of ${drivingSchool}`);
          } else {
            log = true;
          }
          this._onNewSnapshot(drivingSchoolId, studentsById);
          relax = false;
        });
      }
    } catch (error) {
      reportError(`Failed to retrieve all students of ${drivingSchool}`, { error, query: query_ });
      return new Map();
    }
  }

  async assignInstructors(studentUid: string, instructorUids: Array<string>) {
    const assignInstructors = httpsCallable<{ studentUid: string; instructorUids: Array<string> }, void>(
      functions,
      "api/backoffice/users/assign-instructors",
    );
    await assignInstructors({ studentUid, instructorUids });
  }

  async fetchAuthUser(studentUid: string) {
    try {
      const record = await fetchFirebaseAuthUser(studentUid);
      return { data: record };
    } catch (error) {
      reportError(`Failed to fetch auth user of student with ID ${studentUid}`, error);
    }
  }

  private async _convertSnapshotToStudentsById(snapshot: QuerySnapshot): Promise<Map<string, Student>> {
    const studentsById: Map<string, Student> = new Map();
    let timeout = Date.now() + 100;
    for (const doc of snapshot.docs) {
      try {
        const student = studentsProvider.fromFirestore(doc);
        studentsById.set(student.id, student);
      } catch (error) {
        const { path } = doc.ref;
        if (!knownInvalidDocuments.has(path)) {
          knownInvalidDocuments.add(path);
          console.info(`Failed to parse Firestore document ${path} -- ignoring it`, error);
        }
      }
      // Keep the browser responsive if the snapshot is large ...
      if (Date.now() >= timeout) {
        await sleep({ milliseconds: 1 });
        timeout = Date.now() + 100;
      }
    }
    return studentsById;
  }

  private _onNewSnapshot(drivingSchoolId: string, studentsById: Map<string, Student>): void {
    this._studentsByIdByDrivingSchoolId.set(drivingSchoolId, studentsById);
    this._studentsByIdPromisesByDrivingSchoolId.set(drivingSchoolId, Promise.resolve(studentsById));
    for (const listener of this._onUpdateListeners) {
      try {
        listener();
      } catch (error) {
        console.error("listener failed", error);
      }
    }
  }

  private _convertSort(sort: SortPayload): SortPayload {
    if (!this._webinarMode) {
      return sort;
    }
    if (sort.field === "name") {
      return { ...sort, field: "_name" };
    } else if (sort.field === "firstName") {
      return { ...sort, field: "_firstName" };
    } else if (sort.field === "lastName") {
      return { ...sort, field: "_lastName" };
    } else {
      return sort;
    }
  }

  private _convertStudent(
    student: Omit<Student, "firstName" | "lastName" | "name" | "phoneNumber" | "postalAddress" | "invoiceAddress"> & {
      _firstName: Student["firstName"];
      _lastName: Student["lastName"];
      _name: Student["name"];
      _phoneNumber: Student["phoneNumber"];
      _postalAddress: Student["postalAddress"];
      _invoiceAddress: Student["invoiceAddress"];
    },
  ): Student {
    const { _firstName, _lastName, _name, _phoneNumber, _postalAddress, _invoiceAddress } = student;
    if (this._webinarMode) {
      (student as any).firstName = _xxx(_firstName);
      (student as any).lastName = _xxx(_lastName);
      (student as any).name = _xxx(_name);
      (student as any).phoneNumber = _xxx(_phoneNumber);
      (student as any).postalAddress = {
        street: _xxx(_postalAddress.street) ?? "",
        postalCode: _xxx(_postalAddress.postalCode) ?? "",
        city: _xxx(_postalAddress.city) ?? "",
        geoCoordinates: _postalAddress.geoCoordinates,
      } satisfies PostalAddress;
      (student as any).invoiceAddress = _invoiceAddress
        ? ({
            type: "InvoiceAddress",
            firstName: _xxx(_invoiceAddress.firstName),
            lastName: _xxx(_invoiceAddress.lastName),
            company: _xxx(_invoiceAddress.company),
            street: _xxx(_invoiceAddress.street),
            postalCode: _xxx(_invoiceAddress.postalCode),
            city: _xxx(_invoiceAddress.city),
          } satisfies InvoiceAddress)
        : _invoiceAddress;
    } else {
      (student as any).firstName = _firstName;
      (student as any).lastName = _lastName;
      (student as any).name = _name;
      (student as any).phoneNumber = _phoneNumber;
      (student as any).postalAddress = _postalAddress;
      (student as any).invoiceAddress = _invoiceAddress;
    }
    return student as any as Student;
  }
}

function _fullyActivatedStudentsFilter(student: Student): boolean {
  switch (student.verboseStatus) {
    case "Abgebrochen":
    case "Aktiv":
    case "Fertig":
    case "Inaktiv":
    case "Offene Zahlungen":
    case "Pausiert":
    case "Unbekannt":
      return true;
    case "Aktiv (Kein Zahlungsmittel)":
      // Although the student is not fully activated, we do not filter it out, because
      // the main reason for having no payment method is not an incomplete activation
      // but a switch from paymentStrategy: upfrontPayment to paymentStrategy: payAsYouDrive ...
      return true;
    case "Aktiv (Grundbetrag offen)":
    case "Aktiv (Onboarding fehlt)":
      return false;
    default:
      assertNever(student.verboseStatus);
  }
}

function _defaultFeatureToggles(bookedTrainings: Array<BookedTraining>): FeatureTogglesForStudents {
  const canBookTheoryLessons = bookedTrainings.some((it) => it.hasCompulsoryTheoryLessons && !it.drivingSchoolChange);
  const canLearnTheoryInMobileApp = bookedTrainings.length === 0 || canBookTheoryLessons;
  return { lab: false, canBookTheoryLessons, canLearnTheoryInMobileApp };
}

export const studentsProvider = new StudentsProvider();

function _renderVerboseStatus(student: Omit<Student, "verboseStatus">): VerboseStudentStatus {
  const { onboardings, paymentMethod, paymentStrategy, status } = student;
  // This shouldn't happen in production, but we want a meaningful status message if it happens.
  const needsOnboarding = onboardings.length === 0;

  switch (status) {
    case "active":
      if (!paymentMethod && paymentStrategy !== "upfrontPayment" && paymentStrategy !== "purchaseOnAccount") {
        return "Aktiv (Kein Zahlungsmittel)";
      }
      if (needsOnboarding) {
        return "Aktiv (Onboarding fehlt)";
      }
      if (_hasNotPaidBaseFeeYet(student)) {
        return "Aktiv (Grundbetrag offen)";
      }
      return "Aktiv";
    case "cancelled":
      return "Abgebrochen";
    case "completed":
      return "Fertig";
    case "inactive":
      return "Inaktiv";
    case "onHold":
      return "Pausiert";
    case "outstandingPayments":
      return "Offene Zahlungen";
    default:
      return "Unbekannt";
  }
}

function _renderVerboseStatusSinceXxxDays(student: Student): string {
  const { lastStatusChangeAt, verboseStatus } = student;
  if (!lastStatusChangeAt) {
    return verboseStatus;
  }
  let d1 = DateTime.now();
  let d2 = lastStatusChangeAt;
  d1 = DateTime.fromObject({ year: d1.year, month: d1.month, day: d1.day }, { zone: d1.zone });
  d2 = DateTime.fromObject({ year: d2.year, month: d2.month, day: d2.day }, { zone: d2.zone });
  const dateDiff = Math.round(d1.diff(d2, "days").days);
  const since = dateDiff === 0 ? "heute" : dateDiff === 1 ? "gestern" : `${dateDiff} Tagen`;
  if (verboseStatus.endsWith(")")) {
    return `${verboseStatus.substring(0, verboseStatus.length - 1)}, seit ${since})`;
  } else {
    return `${verboseStatus} (seit ${since})`;
  }
}

function _hasNotPaidBaseFeeYet(student: Omit<Student, "verboseStatus">) {
  const quoteIdsOfUnfinishedTrainings = new Set(
    student.bookedTrainings.filter((it) => !it.isFinished).map((it) => it.quoteId),
  );
  const relevantOnboardings = student.onboardings.filter(
    (it) => quoteIdsOfUnfinishedTrainings.has(it.quoteId) && it.baseFee > 0,
  );
  return relevantOnboardings.length > 0 && relevantOnboardings.every((it) => !it.baseFeePaid);
}

function _effectiveStatus(
  status: StudentStatus | undefined,
  bookedTrainings: Array<BookedTraining>,
): StudentStatus | "" {
  if (bookedTrainings.length === 0) {
    return "";
  }
  if (status !== "outstandingPayments") {
    if (bookedTrainings.every((it) => it.isFinished)) {
      return "completed";
    }
  }
  return status ?? "";
}

function _inferActiveOrMostRecentBookedTraining(student: Student): BookedTraining {
  const { bookedTrainings } = student;
  // Fast path if student has only one booked training ...
  if (bookedTrainings.length === 1) {
    return bookedTrainings[0];
  }
  // If all booked trainings are finished, we return the most recent one ...
  if (bookedTrainings.every((it) => it.isFinished)) {
    return bookedTrainings.reduce((bookedTraining1: BookedTraining, bookedTraining2: BookedTraining) => {
      const d1 = bookedTraining1.kpis?.mostRecentDrivingLessonDate ?? DateTime.fromMillis(0);
      const d2 = bookedTraining2.kpis?.mostRecentDrivingLessonDate ?? DateTime.fromMillis(0);
      return d1 > d2 ? bookedTraining1 : bookedTraining2;
    });
  }
  // Otherwise we return the active booked training ...
  return _inferActiveBookedTraining(student);
}

function _inferActiveBookedTraining(student: Student): BookedTraining {
  const { bookedTrainings } = student;
  if (!bookedTrainings.length) {
    throw new Error(`Student ${student.id} (${student.name}) has no booked trainings.`);
  }
  const candidates = bookedTrainings.filter((it) => !it.isFinished);
  if (!candidates.length) {
    throw new Error(`All booked trainings of student ${student.id} (${student.name}) are completed.`);
  }
  // Fast path if there is only one candidate ...
  if (candidates.length === 1) {
    return candidates[0];
  }
  // If there are multiple candidates, we prefer the one with the most recent driving lesson ...
  const candidatesWithDrivingLessons = candidates.filter((it) => it.kpis?.mostRecentDrivingLessonDate);
  if (candidatesWithDrivingLessons.length === 1) {
    return candidates[0];
  } else if (candidatesWithDrivingLessons.length > 1) {
    return candidatesWithDrivingLessons.reduce((a, b) =>
      a.kpis!.mostRecentDrivingLessonDate! > b.kpis!.mostRecentDrivingLessonDate! ? a : b,
    );
  }
  // If the student has no driving lesson yet, we prefer car training over motorcycle training over trailer training ...
  for (const drivingLicenseClasses of [
    CAR_DRIVING_LICENSE_CLASSES,
    MOTORCYCLE_DRIVING_LICENSE_CLASSES,
    TRAILER_DRIVING_LICENSE_CLASSES,
  ]) {
    const bestCandidate = candidates.find((it) => drivingLicenseClasses.has(it.drivingLicenseClass));
    if (bestCandidate) {
      return bestCandidate;
    }
  }
  console.warn(
    `Failed to infer active booked training of student ${student.id} (${student.name}) -- falling back to student.bookedTrainings[0]`,
  );
  return student.bookedTrainings[0];
}

function _xxx(s: string): string;
function _xxx(s: string | undefined): string | undefined;
function _xxx(s: string | undefined) {
  if (typeof s !== "string") {
    return s;
  }
  const a = [];
  for (let i = 0; i < s.length; i++) {
    a.push(s[i].toLowerCase() !== s[i] ? "X" : s[i]);
  }
  return a
    .join("")
    .replaceAll(/[0-9]/g, "X")
    .replaceAll(/[^X +-]/gi, "x");
}
