import { Box, Chip, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { Link, PaginationPayload, useGetList, useGetManyReference } from "react-admin";
import { ApprovalStatus, Student } from "../../providers/studentsProvider";
import { StudentTheoryExamSimulationsField } from "../../fields/StudentTheoryExamSimulationsField";
import EditIcon from "@mui/icons-material/EditOutlined";
import { autovioColors, fullWidthTabsStyle, overviewCardStyle } from "../../misc/backofficeTheme";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CarIcon } from "../../icons/CarIcon";
import { SchalterIcon } from "./SchalterIcon";
import { UberlandIcon } from "./UberlandIcon";
import { AutobahnIcon } from "./AutobahnIcon";
import { NachtIcon } from "./NachtIcon";
import { InstructorIcon } from "../../icons/InstructorIcon";
import { ExamIcon } from "../../icons/ExamIcon";
import { calculateTheoryLearningProgress, INVOICE_STATUS_LABEL_AND_COLOR } from "../../backoffice.utils";
import { InvoiceIcon } from "../../icons/InvoiceIcon";
import { Spinner } from "../../misc/Spinner";
import { formatDate } from "../../utils/calendar";
import { ResetTheoryLearningButton } from "../../buttons/ResetTheoryLearningButton";
import { grants } from "../../backoffice.access_control";
import { LicenseApplicationDialog } from "../../dialogs/LicenseApplicationDialog";
import { useDialog } from "../../hooks/useDialog";
import { ActivitiesTimeline } from "./ActivitiesTimeline";
import { AllActivitiesDialog } from "../../dialogs/AllActivitesDialog";
import { BookedTraining } from "../../model/BookedTraining";

const { grey, green } = autovioColors;

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

type InvoiceStateKey = keyof typeof INVOICE_STATUS_LABEL_AND_COLOR;

export function StudentOverviewTab({ student }: { student: Student }) {
  const [selectedTrainingIndex, setSelectedTrainingIndex] = useState(0);
  const training = student.bookedTrainings[selectedTrainingIndex];
  const navigate = useNavigate();

  return (
    <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Grid container spacing={4}>
        <Grid item lg={6} style={{ paddingLeft: 16 }}>
          <Box sx={{ ...overviewCardStyle, padding: "20px 30px 50px" }}>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: 0 }}>
                <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>Ausbildung</Typography>
                <IconButton
                  sx={{ background: "transparent", color: green, width: 30, height: 30 }}
                  onClick={() => navigate(`/students/${student.id}/packages`)}
                >
                  <EditIcon style={{ fontSize: 20 }} />
                </IconButton>
              </Box>
              {student.bookedTrainings.length > 1 && (
                <Tabs
                  value={selectedTrainingIndex}
                  onChange={(_, value) => setSelectedTrainingIndex(value)}
                  sx={{ ...fullWidthTabsStyle, marginTop: 2 }}
                  TabIndicatorProps={{ style: { display: "none" } }}
                >
                  {student.bookedTrainings.map(({ shortLabel }, index) => (
                    <Tab key={index} sx={{ borderBottom: "none", flex: 1 }} label={shortLabel} {...a11yProps(index)} />
                  ))}
                </Tabs>
              )}
              {student.bookedTrainings.length === 1 && (
                <Typography sx={{ color: grey }}>{student.bookedTrainings[0].shortLabel}</Typography>
              )}
            </Box>
            <_LicenseApplication student={student} training={training} />
            <_Practice training={training} />
            <_Theory student={student} training={training} />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <_LatestActivitiesBox student={student} />
          <div style={{ height: "30px" }} />
          <_LatestInvoiceBox student={student} />
        </Grid>
      </Grid>
    </Box>
  );
}

function _LicenseApplication({ student, training }: { student: Student; training: BookedTraining }) {
  const { administrativeOnboardingState, administrativeOnboardingForChangersState } = student;
  const { dialogProps, openDialog } = useDialog();

  let status: ApprovalStatus | undefined;
  if (administrativeOnboardingState) {
    status = administrativeOnboardingState.applicationApproved;
  } else if (administrativeOnboardingForChangersState) {
    status = administrativeOnboardingForChangersState.changeApproved;
  }

  if (!training.isForDrivingLicense || !status) {
    return null;
  }

  return (
    <>
      <Box sx={{ marginTop: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
            {administrativeOnboardingState ? "Führerscheinantrag" : "Fahrschulwechsel"}
          </Typography>
          <Typography sx={{ fontSize: 14, color: grey }}>
            {status === "approved"
              ? `Prüfauftrag gültig bis ${
                  student.examinationAssignmentExpiresAt ? formatDate(student.examinationAssignmentExpiresAt) : "???"
                }`
              : status === "pending"
              ? "Genehmigung ausstehend"
              : status === "notApproved"
              ? "Genehmigung abgelehnt"
              : `[${status}]`}
          </Typography>
        </Box>
        <IconButton sx={{ background: "transparent", color: green, width: 30, height: 30 }} onClick={openDialog}>
          <EditIcon style={{ fontSize: 20 }} />
        </IconButton>
      </Box>
      <LicenseApplicationDialog student={student} {...dialogProps} />
    </>
  );
}

function _Practice({ training }: { training: BookedTraining }) {
  if (!training.isForDrivingLicense) {
    return null;
  }

  const kpis = training?.kpis;
  const finishedDrivingLessonsCount = kpis?.finishedDrivingLessonsCount;

  return (
    <Box sx={{ marginTop: 3 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>Praxis</Typography>
      <Grid container spacing={2} sx={{ marginTop: "-12px" }}>
        <Grid item lg={6}>
          <_TrainingItem icon={<CarIcon />} label="Übung" value={finishedDrivingLessonsCount?.normal} unit="UE" />
        </Grid>
        <Grid item lg={6}>
          {" "}
          <_TrainingItem
            icon={<SchalterIcon />}
            label="Schalter"
            value={kpis?.finishedManualGearLessonsCount}
            unit="UE"
          />
        </Grid>
        <Grid item lg={6}>
          {" "}
          <_TrainingItem
            icon={<UberlandIcon />}
            label="Überland"
            value={finishedDrivingLessonsCount?.ueberlandfahrt}
            unit="UE"
          />
        </Grid>
        <Grid item lg={6}>
          {" "}
          <_TrainingItem
            icon={<AutobahnIcon />}
            label="Autobahn"
            value={finishedDrivingLessonsCount?.autobahnfahrt}
            unit="UE"
          />
        </Grid>
        <Grid item lg={6}>
          {" "}
          <_TrainingItem icon={<NachtIcon />} label="Nacht" value={finishedDrivingLessonsCount?.nachtfahrt} unit="UE" />
        </Grid>
      </Grid>
    </Box>
  );
}

function _Theory({ student, training }: { student: Student; training: BookedTraining }) {
  if (!training.isForDrivingLicense) {
    return null;
  }

  const theoryLearningProgress = calculateTheoryLearningProgress(student.theoryKPIs?.theoryLearningProgress);

  return (
    <>
      <Box sx={{ marginTop: 3 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>Theorie</Typography>
        <Grid container spacing={2} sx={{ marginTop: "-12px" }}>
          <Grid item lg={6}>
            {" "}
            <_TrainingItem
              icon={<InstructorIcon />}
              label="Unterricht"
              value={student.theoryKPIs?.finishedTheoryLessonsCount}
              unit="Lekt."
            />
          </Grid>
          <Grid item lg={6}>
            {" "}
            <_TrainingItem
              icon={<ExamIcon />}
              value={theoryLearningProgress}
              label="Lernstand"
              unit="%"
              trailing={
                theoryLearningProgress !== "0,0" &&
                grants.includes("resetTheoryLearning") && <ResetTheoryLearningButton />
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: 3 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>Prüfungssimulationen</Typography>
        <Box sx={{ marginLeft: -1, marginTop: 4 }}>
          <StudentTheoryExamSimulationsField />
        </Box>
      </Box>
    </>
  );
}

function _TrainingItem({
  icon,
  label,
  value,
  unit,
  trailing,
}: {
  icon: JSX.Element;
  label: string;
  value?: number | string;
  unit?: string;
  trailing?: React.ReactNode;
}) {
  const formatValue = (val?: number | string) => {
    if (val && typeof val === "number") return Math.floor(val);
    return val;
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box>
        <IconButton
          sx={{
            background: green,
            color: "white",
            cursor: "default",
            ":hover": {
              background: green,
            },
          }}
        >
          {icon}
        </IconButton>
      </Box>
      <Box width="16px" />
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 19 }}>{formatValue(value) || 0}</Typography>
          <Typography sx={{ fontSize: 14 }}>{unit || null}</Typography>
        </Box>
        <Typography sx={{ color: grey, fontSize: 12, marginTop: -0.5 }}>{label}</Typography>
      </Box>
      {trailing}
    </Box>
  );
}

function _LatestActivitiesBox({ student }: { student: Student }) {
  const { data } = useGetManyReference("studentActivities", {
    target: "studentUid",
    id: student.id,
    pagination: { page: 1, perPage: 5 },
    sort: { field: "dateTime", order: "DESC" },
  });
  const { dialogProps, openDialog } = useDialog();

  return (
    <>
      <Box
        sx={{
          ...overviewCardStyle,
          maxHeight: 410,
          padding: "25px 30px",
          position: "relative",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: 15, mb: "20px" }}>Neueste Aktivitäten</Typography>
        <ActivitiesTimeline student={student} />
        {data && data.length > 0 && (
          <Typography sx={{ color: green, marginTop: -2, cursor: "pointer", fontSize: "14px" }} onClick={openDialog}>
            Alle Aktivitäten
          </Typography>
        )}
      </Box>
      <AllActivitiesDialog student={student} {...dialogProps} />
    </>
  );
}

function _LatestInvoiceBox({ student }: { student: Student }) {
  const { data } = useGetList("b2cInvoices", {
    filter: { studentId: student.id },
    pagination: { perPage: 5 } as PaginationPayload,
    sort: { field: "name", order: "ASC" },
  });

  return (
    <Box
      sx={{
        ...overviewCardStyle,
        maxHeight: 380,
        padding: "20px 30px",
        position: "relative",
      }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: 15, mb: "20px" }}>Letzte Rechnungen</Typography>
      {!data && (
        <div className="centered">
          <Spinner style={{ margin: "20px" }} />
        </div>
      )}
      {data &&
        data?.map((invoice) => (
          <Box key={invoice.id} sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: 2 }}>
            <IconButton sx={{ background: "#f2f2f2" }}>
              <InvoiceIcon />
            </IconButton>
            <Box sx={{ flex: 3 }}>
              <Typography sx={{ fontSize: 14 }}>{invoice.nr}</Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: grey,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 170,
                }}
              >
                {invoice.payload.positions[0]?.description}
              </Typography>
            </Box>
            <Box>
              <Tooltip
                title={INVOICE_STATUS_LABEL_AND_COLOR[invoice.status as InvoiceStateKey]?.label}
                placement="bottom-end"
              >
                <Chip
                  sx={{
                    background: INVOICE_STATUS_LABEL_AND_COLOR[invoice.status as InvoiceStateKey]?.bgColor,
                    color: INVOICE_STATUS_LABEL_AND_COLOR[invoice.status as InvoiceStateKey]?.color,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 65,
                    height: 20,
                    fontSize: 9,
                    fontWeight: "600",
                  }}
                  label={INVOICE_STATUS_LABEL_AND_COLOR[invoice.status as InvoiceStateKey]?.label?.toUpperCase()}
                />
              </Tooltip>
            </Box>
          </Box>
        ))}
      {data && data.length === 0 && (
        <Typography sx={{ color: grey, marginTop: -1, fontSize: 14 }}>Noch keine Rechnung</Typography>
      )}
      {data && data.length > 0 && (
        <Link to={`/students/${student.id}/invoices`}>
          <Typography sx={{ fontSize: 14 }}>Alle Rechnungen</Typography>
        </Link>
      )}
    </Box>
  );
}
