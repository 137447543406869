import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const CreateASFCourseDto = z.object({
  drivingSchoolUid: z.string().min(1),
  courseUid: z.string().uuid(),
  sessions: z
    .array(
      z.object({
        uid: z.string().uuid(),
        start: z.string().datetime({ offset: true }),
        end: z.string().datetime({ offset: true }),
        location: z.object({ street: z.string().min(1), postalCode: z.string().min(1), city: z.string().min(1) }),
        instructorUid: z.string().min(1),
      }),
    )
    .min(5)
    .max(5),
});
const AddStudentToCourseDto = z
  .object({
    studentUid: z.string().min(1),
    courseUid: z.string().min(1),
    agreedCancellationPolicy: z
      .object({
        rules: z.array(
          z
            .object({
              precondition: z.object({
                var: z.enum(["timeSinceBooked", "timeBeforeStart"]),
                op: z.literal("<"),
                value: z.string().regex(/^([1-9][0-9]*)([hd])$/),
              }),
              fee: z.union([
                z.object({
                  fixed: z
                    .object({ amount: z.union([z.number(), z.string()]), currency: z.literal("EUR") })
                    .passthrough(),
                }),
                z.object({ percentage: z.number().int().gte(0).lte(100) }),
              ]),
            })
            .passthrough(),
        ),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const RemoveStudentFromCourseDto = z
  .object({ studentUid: z.string().min(1), courseUid: z.string().min(1) })
  .passthrough();
const ASFCourseDto = z
  .object({
    uid: z.string(),
    type: z.literal("ASFCourse"),
    appointments: z.array(
      z
        .object({
          uid: z.string(),
          sessionType: z.enum(["sitzung1", "fahrprobe", "sitzung2", "sitzung3", "sitzung4"]),
          start: z.string().datetime({ offset: true }),
          end: z.string().datetime({ offset: true }),
          instructor: z.object({ uid: z.string(), name: z.string(), avatarUrl: z.string().optional() }).passthrough(),
          location: z
            .object({
              street: z.string(),
              postalCode: z.string(),
              city: z.string(),
              geoCoordinates: z.object({ lat: z.number(), lng: z.number() }).passthrough().optional(),
            })
            .passthrough(),
        })
        .passthrough(),
    ),
    numFreeSeats: z.number().int(),
  })
  .passthrough();
const UnblockStudentDto = z.object({ reason: z.string().min(1) });
const StudentActivityDto = z
  .object({
    dateTime: z.string().datetime({ offset: true }),
    label: z.string().min(1),
    calendarEvent: z.discriminatedUnion("type", [
      z.object({ uid: z.string().min(1), type: z.literal("TheoryLesson") }).passthrough(),
      z
        .object({
          uid: z.string().min(1),
          type: z.literal("DrivingLesson"),
          drivingLessonType: z.enum([
            "normal",
            "ueberlandfahrt",
            "autobahnfahrt",
            "nachtfahrt",
            "praktischePruefung",
            "schaltkompetenz",
            "theoretischePruefung",
            "fahrprobe",
          ]),
          duration: z.object({ minutes: z.number().int() }).passthrough(),
        })
        .passthrough(),
    ]),
  })
  .passthrough();
const CreatePromoCodeDto = z.object({
  code: z.string().min(1),
  description: z.string().optional(),
  drivingSchoolId: z.string().min(1).optional(),
  isEnabled: z.boolean().optional().default(true),
  start: z.string().datetime({ offset: true }),
  end: z.string().datetime({ offset: true }),
  startCredits: z.union([z.string(), z.number()]),
  maxUses: z.number().int(),
  autovioShare: z.union([z.string(), z.number()]).optional().default(1),
});
const PromoCodeDto = z
  .object({
    id: z.string().uuid(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    createdById: z.string(),
    updatedAt: z.string().datetime({ offset: true }).nullable(),
    updatedById: z.string(),
    drivingSchoolId: z.string().nullish(),
    code: z.string(),
    normalizedCode: z.string(),
    description: z.string(),
    isEnabled: z.boolean(),
    start: z.string().datetime({ offset: true }).nullable(),
    end: z.string().datetime({ offset: true }).nullable(),
    startCredits: z.string(),
    maxUses: z.number().int(),
    autovioShare: z.string(),
    numUsed: z.number(),
  })
  .passthrough();
const ReservePromoCodeRequest = z.object({ code: z.string().min(1), drivingSchoolId: z.string().min(1) });
const ReservePromoCodeResponse = z
  .object({
    id: z.string().min(1),
    reservedUntil: z.string().datetime({ offset: true }),
    startCredits: z.object({ amount: z.string(), currency: z.literal("EUR") }).passthrough(),
  })
  .passthrough();
const PromoCodeExpiredErrorDto = z.object({ error: z.literal("expired") });
const TooManyUsagesErrorDto = z.object({ error: z.literal("too-many-usages") });
const UpdatePromoCodeDto = z
  .object({
    id: z.string().min(1),
    code: z.string().min(1),
    description: z.string(),
    drivingSchoolId: z.string().min(1),
    isEnabled: z.boolean().default(true),
    start: z.string().datetime({ offset: true }),
    end: z.string().datetime({ offset: true }),
    startCredits: z.union([z.string(), z.number()]),
    maxUses: z.number().int(),
    autovioShare: z.union([z.string(), z.number()]).default(1),
  })
  .partial();
const AppliedReferralCodeDto = z
  .object({
    referralCode: z.string(),
    numApplied: z.number(),
    lastAppliedAt: z.string().datetime({ offset: true }),
    student: z
      .object({
        id: z.string(),
        name: z.string(),
        status: z.enum(["active", "cancelled", "inactive", "onHold", "outstandingPayments", "completed", "unknown"]),
        verboseStatus: z.string(),
      })
      .passthrough(),
    drivingSchool: z.object({ id: z.string(), name: z.string() }).passthrough(),
  })
  .passthrough();
const ReferralCodeApplicationDto = z
  .object({
    appliedAt: z.string().datetime({ offset: true }),
    appliedBy: z.object({ id: z.string(), name: z.string() }).passthrough(),
  })
  .passthrough();
const PatchUserDto = z.object({ firstName: z.string(), lastName: z.string() }).partial();
const UserDto = z.object({
  createdAt: z.string().datetime({ offset: true }).nullable(),
  updatedAt: z.string().datetime({ offset: true }).nullable(),
  firstName: z.string(),
  id: z.string(),
  lastName: z.string(),
});
const SendResetPasswordEmailRequest = z.object({ email: z.string().email() }).passthrough();
const TableauJwtRequest = z.object({ drivingSchoolUid: z.string() }).partial().passthrough();
const TableauJwtResponse = z.object({ jwt: z.string() }).passthrough();
const BookQuoteDto = z
  .object({
    assistedDrivingBF17: z.boolean(),
    dateOfBirth: z.string().regex(/^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/),
    placeOfBirth: z.string(),
    reference: z.string(),
    contactData: z
      .object({ email: z.string().email(), phone: z.string() })
      .passthrough()
      .and(
        z
          .object({ street: z.string(), postalCode: z.string(), city: z.string(), company: z.string().optional() })
          .passthrough(),
      )
      .and(z.object({ firstName: z.string(), lastName: z.string() }).passthrough()),
    invoiceAddress: z
      .object({ street: z.string(), postalCode: z.string(), city: z.string(), company: z.string().optional() })
      .passthrough()
      .and(z.object({ firstName: z.string(), lastName: z.string() }).passthrough())
      .optional(),
    guardian: z
      .object({ email: z.string().email(), phone: z.string() })
      .passthrough()
      .and(z.object({ firstName: z.string(), lastName: z.string() }).passthrough())
      .optional(),
    discountCode: z.string().optional(),
    promoCodeUsageId: z.string().optional(),
    legal: z
      .object({
        gdprPrivacyPolicy: z
          .object({ consent: z.boolean(), checkboxText: z.string(), subscriptionId: z.string().optional() })
          .passthrough(),
        marketing: z
          .object({ consent: z.boolean(), checkboxText: z.string(), subscriptionId: z.string().optional() })
          .passthrough()
          .optional(),
      })
      .passthrough(),
    quote: z.object({ quoteId: z.string(), quoteName: z.string() }).passthrough(),
    branchId: z.string().optional(),
  })
  .passthrough();
const PayBaseFeeResponseDto = z
  .object({
    paidPositions: z.object({ baseFee: z.number(), teachingMaterialFee: z.number().optional() }).passthrough(),
  })
  .passthrough();
const _PayBaseFeeRequestDto = z
  .object({ data: z.object({ idempotencyKey: z.string().min(1), quoteId: z.string().min(1) }).passthrough() })
  .passthrough();
const _PayBaseFeeResponseDto = z
  .object({
    data: z
      .object({
        paidPositions: z.object({ baseFee: z.number(), teachingMaterialFee: z.number().optional() }).passthrough(),
      })
      .passthrough(),
  })
  .passthrough();
const SendDunningNoticeRequest = z
  .object({ dunningProcessId: z.string().uuid(), emailSubject: z.string(), emailText: z.string() })
  .passthrough();
const BlockStudentAndSendPaymentReminderRequest = z
  .object({
    dunningProcessId: z.string().uuid(),
    emailSubject: z.string(),
    emailText: z.string(),
    studentUid: z.string(),
  })
  .passthrough();
const ClaimRewardRequestBody = z
  .object({
    referralCodeUsageId: z.string().min(1),
    value: z.object({ amount: z.union([z.number(), z.string()]), currency: z.literal("EUR") }).passthrough(),
    utid: z.string().min(1).optional(),
  })
  .passthrough();
const ClaimRewardResponseBody = z
  .object({ amount: z.string(), currency: z.literal("EUR"), description: z.string() })
  .passthrough();
const CreateGiftVoucherRequestBody = z
  .object({
    id: z.string().min(1),
    beneficiary: z.string().min(1),
    value: z.object({ amount: z.union([z.number(), z.string()]), currency: z.literal("EUR") }).passthrough(),
    description: z.string().min(1),
    note: z.string().optional(),
  })
  .passthrough();
const GiftVoucherDto = z
  .object({
    id: z.string().uuid(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    createdById: z.string(),
    beneficiaryId: z.string(),
    amount: z.string(),
    currency: z.literal("EUR"),
    description: z.string(),
  })
  .passthrough();
const SignUpData = z
  .object({
    id: z.string(),
    updatedAt: z.unknown().nullable(),
    createdAt: z.unknown().nullable(),
    updatedBy: z.string().uuid().optional(),
    createdBy: z.string().uuid().optional(),
    writeId: z.string().uuid(),
    paymentConfiguration: z
      .object({
        allowedPaymentSelections: z.array(
          z.enum([
            "financing",
            "pay_as_you_drive",
            "invoice_payment",
            "prepaid",
            "prepaid_bank_transfer",
            "prepaid_card",
            "prepaid_sepa_debit",
          ]),
        ),
        selectedPaymentSelection: z.enum([
          "financing",
          "pay_as_you_drive",
          "invoice_payment",
          "prepaid",
          "prepaid_bank_transfer",
          "prepaid_card",
          "prepaid_sepa_debit",
        ]),
        selectedNumberLessons: z.record(z.number()),
      })
      .partial()
      .passthrough()
      .optional(),
    formData: z.record(z.object({}).partial().passthrough()).optional().default({}),
  })
  .passthrough();
const SignUpDataUpdateDto = z
  .object({
    id: z.string(),
    paymentConfiguration: z
      .object({
        allowedPaymentSelections: z.array(
          z.enum([
            "financing",
            "pay_as_you_drive",
            "invoice_payment",
            "prepaid",
            "prepaid_bank_transfer",
            "prepaid_card",
            "prepaid_sepa_debit",
          ]),
        ),
        selectedPaymentSelection: z.enum([
          "financing",
          "pay_as_you_drive",
          "invoice_payment",
          "prepaid",
          "prepaid_bank_transfer",
          "prepaid_card",
          "prepaid_sepa_debit",
        ]),
        selectedNumberLessons: z.record(z.number()),
      })
      .partial()
      .passthrough()
      .optional(),
    formData: z.record(z.object({}).partial().passthrough()).optional().default({}),
  })
  .passthrough();

export const schemas = {
  CreateASFCourseDto,
  AddStudentToCourseDto,
  RemoveStudentFromCourseDto,
  ASFCourseDto,
  UnblockStudentDto,
  StudentActivityDto,
  CreatePromoCodeDto,
  PromoCodeDto,
  ReservePromoCodeRequest,
  ReservePromoCodeResponse,
  PromoCodeExpiredErrorDto,
  TooManyUsagesErrorDto,
  UpdatePromoCodeDto,
  AppliedReferralCodeDto,
  ReferralCodeApplicationDto,
  PatchUserDto,
  UserDto,
  SendResetPasswordEmailRequest,
  TableauJwtRequest,
  TableauJwtResponse,
  BookQuoteDto,
  PayBaseFeeResponseDto,
  _PayBaseFeeRequestDto,
  _PayBaseFeeResponseDto,
  SendDunningNoticeRequest,
  BlockStudentAndSendPaymentReminderRequest,
  ClaimRewardRequestBody,
  ClaimRewardResponseBody,
  CreateGiftVoucherRequestBody,
  GiftVoucherDto,
  SignUpData,
  SignUpDataUpdateDto,
};

const endpoints = makeApi([
  {
    method: "get",
    path: "/applied-referral-codes",
    alias: "listAppliedReferralCodes",
    requestFormat: "json",
    parameters: [
      {
        name: "range",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "filter",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.array(AppliedReferralCodeDto),
  },
  {
    method: "post",
    path: "/calendar/add-student-to-course",
    alias: "addStudentToCourse",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AddStudentToCourseDto,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/calendar/course/:courseUid",
    alias: "getCourse",
    requestFormat: "json",
    parameters: [
      {
        name: "courseUid",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: ASFCourseDto,
  },
  {
    method: "post",
    path: "/calendar/new-ASF-course",
    alias: "createASFCourse",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateASFCourseDto,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/calendar/remove-student-from-course",
    alias: "removeStudentFromCourse",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: RemoveStudentFromCourseDto,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/contract/preview/:id",
    alias: "previewPdf",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/contract/update-contracts/:id",
    alias: "updateContractsOfStudent",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/documents/asf_course_certificate_of_attendance/:studentId/:asfCourseId",
    alias: "createASFCourseCertificateOfAttendance",
    requestFormat: "json",
    parameters: [
      {
        name: "studentId",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "asfCourseId",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "studentSignatureStorageRef",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "instructorSignatureStorageRef",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 409,
        description: `Student did not attend all sessions of the ASF course`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "post",
    path: "/dunning/block-student-and-send-payment-reminder",
    alias: "blockStudentAndSendPaymentReminder",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: BlockStudentAndSendPaymentReminderRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/dunning/first-dunning-notice",
    alias: "sendFirstDunningNotice",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SendDunningNoticeRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/dunning/handover-to-PAIR-Finance",
    alias: "handoverToPairFinance",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SendDunningNoticeRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/dunning/second-dunning-notice",
    alias: "sendSecondDunningNotice",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SendDunningNoticeRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/dunning/warning",
    alias: "sendWarning",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SendDunningNoticeRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/payment/pay-base-fee",
    alias: "payBaseFee",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: _PayBaseFeeRequestDto,
      },
    ],
    response: _PayBaseFeeResponseDto,
  },
  {
    method: "post",
    path: "/promo-codes",
    alias: "createPromoCode",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreatePromoCodeDto,
      },
    ],
    response: PromoCodeDto,
  },
  {
    method: "get",
    path: "/promo-codes",
    alias: "listPromoCodes",
    requestFormat: "json",
    parameters: [
      {
        name: "range",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "filter",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.array(PromoCodeDto),
  },
  {
    method: "put",
    path: "/promo-codes/:id",
    alias: "updatePromoCode",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdatePromoCodeDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: PromoCodeDto,
  },
  {
    method: "get",
    path: "/promo-codes/:id",
    alias: "getPromoCode",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: PromoCodeDto,
  },
  {
    method: "post",
    path: "/promo-codes/reserve",
    alias: "reservePromoCode",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ReservePromoCodeRequest,
      },
    ],
    response: ReservePromoCodeResponse,
    errors: [
      {
        status: 404,
        description: `PromoCode not found`,
        schema: z.void(),
      },
      {
        status: 409,
        schema: z.union([PromoCodeExpiredErrorDto, TooManyUsagesErrorDto]),
      },
    ],
  },
  {
    method: "get",
    path: "/referral-code-applications",
    alias: "listReferralCodeApplications",
    requestFormat: "json",
    parameters: [
      {
        name: "code",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.array(ReferralCodeApplicationDto),
  },
  {
    method: "post",
    path: "/rewards/claim",
    alias: "claimReward",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ClaimRewardRequestBody,
      },
    ],
    response: ClaimRewardResponseBody,
  },
  {
    method: "post",
    path: "/rewards/gift-voucher",
    alias: "createGiftVoucher",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateGiftVoucherRequestBody,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/rewards/gift-vouchers",
    alias: "findGiftVouchers",
    requestFormat: "json",
    parameters: [
      {
        name: "filter",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "range",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.array(GiftVoucherDto),
  },
  {
    method: "patch",
    path: "/sign-up-data",
    alias: "updateSignUpData",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SignUpDataUpdateDto,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/sign-up-data/:id",
    alias: "getSignUpData",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: SignUpData,
  },
  {
    method: "get",
    path: "/students/:id/activities",
    alias: "listStudentActivities",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "range",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "filter",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.array(StudentActivityDto),
  },
  {
    method: "put",
    path: "/students/:id/unblock",
    alias: "unblockStudent",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ reason: z.string().min(1) }),
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/tableau/jwt",
    alias: "getTableauJwt",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ drivingSchoolUid: z.string() }).partial().passthrough(),
      },
    ],
    response: z.object({ jwt: z.string() }).passthrough(),
  },
  {
    method: "patch",
    path: "/user/:id",
    alias: "updateUser",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: PatchUserDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: UserDto,
  },
  {
    method: "post",
    path: "/user/send-reset-password-email",
    alias: "sendPasswordResetEmail",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ email: z.string().email() }).passthrough(),
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/webapp/book-quote",
    alias: "bookQuote",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: BookQuoteDto,
      },
    ],
    response: PayBaseFeeResponseDto,
    errors: [
      {
        status: 461,
        description: `Quote already booked`,
        schema: z.void(),
      },
      {
        status: 462,
        description: `User is already a student of another driving school`,
        schema: z.void(),
      },
    ],
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
