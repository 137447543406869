import { Tabs } from "../misc/Tabs";
import { Tab } from "react-admin";
import { NotesList } from "./NotesList";
import { DocumentsList } from "./DocumentsList";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { autovioColors, overviewCardStyle } from "../misc/backofficeTheme";
import { CSSProperties, useRef } from "react";

export type NotesAndDocumentsSource = "student" | "drivingSchool";

export function NotesAndDocuments({ source }: { source: NotesAndDocumentsSource }) {
  return (
    <Box sx={{ ...overviewCardStyle, marginTop: 1, padding: "25px 30px" }} className="large-screen">
      <_NotesAndDocuments source={source} />
    </Box>
  );
}

export function _NotesAndDocuments({
  showToggleButton,
  handleDrawerToggle,
  open,
  style,
  source,
}: {
  handleDrawerToggle?: () => void;
  open?: boolean;
  showToggleButton?: boolean;
  style?: CSSProperties;
  source: NotesAndDocumentsSource;
}) {
  const { green } = autovioColors;
  const ref = useRef<{ showForm: () => void }>();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "16px",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>Notizen & Dokumente</Typography>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <IconButton sx={{ color: green, width: 30, height: 30 }} onClick={() => ref.current?.showForm()}>
            <AddIcon style={{ fontSize: 24 }} />
          </IconButton>
          {showToggleButton && (
            <IconButton sx={{ color: green, width: 30, height: 30 }} onClick={() => handleDrawerToggle?.()}>
              <KeyboardArrowDownIcon style={{ fontSize: 24, transform: open ? "rotate(0deg)" : "rotate(180deg)" }} />
            </IconButton>
          )}
        </Box>
      </Box>
      <Tabs sx={{}} syncWithLocation={false} fullWidth>
        <Tab label="Notizen">
          <NotesList ref={ref} style={style} resource={source === "student" ? "studentNotes" : "drivingSchoolNotes"} />
        </Tab>
        <Tab label="Dokumente">
          <DocumentsList
            ref={ref}
            style={style}
            resource={source === "student" ? "studentDocuments" : "drivingSchoolDocuments"}
          />
        </Tab>
      </Tabs>
    </>
  );
}
